import React from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';

import {useAppSelector} from '../../../hooks/redux';

const AuthRoute:React.FC<RouteProps> = ({component: Component, ...rest}) => {
    const {user} = useAppSelector(state => state.auth);
    if(!Component) return null;
    return (
        <Route {...rest} render={props => !user ? (
            <Redirect to={{pathname: '/', state: {from: props.location}}}/>
        ) : (
           <Component {...props}/>
        )} />
    )
}

export default AuthRoute;
