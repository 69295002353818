import React from 'react';
import PasswordChecklist from "react-password-checklist";
import {RouteComponentProps} from 'react-router-dom';
import {Row, Col, Card, Form, Input, Button, message} from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import './styles.less';
import Logo from '../../components/ui/logo';
import {useQuery} from '../../hooks/useQuery';
import AuthVector from '../../components/ui/vectors/auth';

import {resetPassword} from '../../redux/auth/auth-slice';
import {useAppSelector, useAppDispatch} from '../../hooks/redux';

interface IProps{
    history: RouteComponentProps["history"];
    match: RouteComponentProps["match"];
}

const ValidIcon = <CheckCircleFilled style={{ marginRight: '.5rem', color: '#00b256' }} />;
const InvalidIcon = <CloseCircleFilled style={{ marginRight: '.5rem', color: '#de2f40' }} />;

const PasswordReset:React.FC<IProps> = ({history}):JSX.Element => {
    const {status} = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const [password, setPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(false);

    let query = useQuery();
    const [form] = Form.useForm();

    React.useEffect(() => {
        const reset_token = query.get('reset_token');
        if(!reset_token) history.goBack(); 
    },[query, history])

    const onFinish = async (values: any):Promise<any> => {
        const key = query.get('reset_token');
        if (!isPasswordValid) {
            form.setFields([
                { name: 'password', errors: ['Your password is too weak'] },
                { name: 'confirmPassword', errors: ['Your password is too weak'] },
            ])
            return;
        }
        else if(values.newPassword !== values.confirmPassword){
            form.setFields([
                {name: 'password', errors: ['Your passwords do not match']},
                {name: 'confirmPassword', errors: ['Your passwords do not match']},
            ]);
            return;
        }
        const payload = {...values, key};
        delete payload.confirmPassword;

        const {error}:any = await dispatch(resetPassword(payload));
        if(!error){
            history.push('/login');
            message.success('Password reset successful. Please login.')
        }
    }

    const goToRegister = ():void => history.push('/register');

    return (
        <section className="pr">
            <Row>
                <Col lg={16} md={24} sm={24} xs={24}>
                    <Card bordered={false} className="pr__form">
                        <Logo/>
                        <Row className="pr__form__row" justify="center" align='middle'>
                            <Col xl={10} lg={14} md={12} sm={14} xs={24}>
                                <h1 className="pr__form__heading">
                                    Create a new password
                                </h1>

                                <p className="pr__form__text --space-bottom">
                                    Enter your new password for your Pesawise account. <strong>NB:</strong>{' '}
                                    Do not leave this page until the following action is completed.
                                </p>

                                <Form
                                    form={form}
                                    name="password_reset"
                                    initialValues={{}}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <Form.Item
                                        name="newPassword"
                                        label="Password"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Password is required!' }]}
                                    >
                                        <Input.Password placeholder="********" onChange={e => setPassword(e.target.value)} size="large" autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item
                                        name="confirmPassword"
                                        label="Repeat your password"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Repeat your password!' }]}
                                    >
                                        <Input.Password placeholder="********" onChange={e => setConfirmPassword(e.target.value)} size="large" autoComplete="off" />
                                    </Form.Item>

                                    <PasswordChecklist
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPassword}
                                        iconComponents={{ ValidIcon, InvalidIcon }}
                                        onChange={(isValid) => setIsPasswordValid(isValid)}
                                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                                    />

                                    <br/>

                                    <p className="pr__form__link">
                                       Trouble resetting your password? <span>Contact us</span>
                                    </p>

                                    <Form.Item className="pr__space-top">
                                        <Button 
                                            block
                                            size="large"
                                            type="primary" 
                                            htmlType="submit" 
                                            loading={status === 'pending'}
                                        >
                                            Reset password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col lg={8} md={0}>
                    <Card bordered={false} className="pr__banner">
                        <Row gutter={[20, 0]} justify="end" align="middle">
                            <Col>
                                <p className="pr__banner__text">
                                    Don't have an account?
                                </p>
                            </Col>
                            <Col>
                                <Button onClick={goToRegister} size="large">
                                    Sign up
                                </Button>    
                            </Col>
                        </Row>

                        <div className="pr__banner__container">
                            <AuthVector as="password"/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </section>
    )
}

export default PasswordReset;
