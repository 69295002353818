import React from 'react';
import PasswordChecklist from "react-password-checklist";
import { Link, RouteComponentProps } from 'react-router-dom';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Row, Col, Card, Form, Input, Button, Select, Checkbox } from 'antd';

import './styles.less';
import Logo from '../../components/ui/logo';
import AuthVector from '../../components/ui/vectors/auth';

import { registerUser } from '../../redux/auth/auth-slice';
import { getLocales } from '../../redux/locale/locale-slice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getBuisnessClass } from '../../redux/business/business-slice';

const { Option } = Select;

interface IProps {
    history: RouteComponentProps["history"];
    match: RouteComponentProps["match"];
}

interface IUserInfo {
    localeId: string;
    businessName: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword?: string;
    terms?: boolean;
    captchaToken: string;
}

const ValidIcon = <CheckCircleFilled style={{ marginRight: '.5rem', color: '#00b256' }} />;
const InvalidIcon = <CloseCircleFilled style={{ marginRight: '.5rem', color: '#de2f40' }} />;

const SignUp: React.FC<IProps> = ({ history }): JSX.Element => {
    const { status, user } = useAppSelector(state => state.auth);
    const { locales, localeStatus } = useAppSelector(state => state.locale);
    const { status:businessStatus, businessClass } = useAppSelector(state => state.business);
    const dispatch = useAppDispatch();

    const [password, setPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(false);

    const [form] = Form.useForm();

    React.useEffect(() => {
        dispatch(getLocales());
        dispatch(getBuisnessClass());
    }, [dispatch]);

    React.useEffect(() => {
        if (user) history.push('/dashboard');
    }, [user, history]);

    const onFinish = async (values: any): Promise<any> => {
        if (!isPasswordValid) {
            form.setFields([
                { name: 'password', errors: ['Your password is too weak'] },
                { name: 'confirmPassword', errors: ['Your password is too weak'] },
            ])
            return;
        }
        const userInfo: IUserInfo = Object.assign({ captchaToken: '' }, values);
        delete userInfo.confirmPassword;
        delete userInfo.terms;

        const { error }: any = await dispatch(registerUser(userInfo));
        if (!error) {
            history.push(`/email/verification?verify=${userInfo.email}`);
        }
    }

    const onFinishFailed = (errorInfo: any): void => {
        console.log('Failed:', errorInfo);
    };

    const goToLogin = (): void => history.push('/');

    return (
        <section className="register">
            <Row>
                <Col xl={16} lg={24} md={24} sm={24} xs={24}>
                    <Card bordered={false} className="register__form">
                        <Logo />
                        <Row className="register__form__row" justify="center" align='middle'>
                            <Col xl={10} lg={9} md={12} sm={16} xs={22}>
                                <h1 className="register__form__heading">
                                    Open a new Pesawise account today
                                </h1>

                                <p className="register__form__text --space-bottom">
                                    Pesawise Business helps you save time and money,
                                    while giving you total control over your finances.
                                </p>

                                <Form
                                    form={form}
                                    name="register"
                                    initialValues={{}}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                    scrollToFirstError
                                >
                                    <Form.Item
                                        name="localeId"
                                        label="Select your country"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Country is required!' }]}
                                    >
                                        <Select
                                            size="large"
                                            loading={localeStatus === 'pending'}
                                        >
                                            {locales.map((locale) => (
                                                <Option key={locale.id} value={locale.id}>
                                                    {locale.countryName.toUpperCase()}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    
                                    <Form.Item
                                        name="businessClassificationid"
                                        label="What type of business is it ?"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Business type is required' }]}
                                    >
                                        <Select
                                            size="large"
                                            loading={businessStatus === 'pending'}
                                        >
                                            {businessClass.map((item) => (
                                                <Option key={item.id} value={item.id}>
                                                    {item.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name="businessName"
                                        label="Business name"
                                        hasFeedback
                                        tooltip="Your registered business name. This name cannot be edited, so make sure you don't have any typos."
                                        rules={[{ required: true, message: 'Business name is required!' }]}
                                    >
                                        <Input placeholder="" size="large" autoComplete="off" />
                                    </Form.Item>

                                    <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name="firstName"
                                                label="First name"
                                                hasFeedback
                                                rules={[{ required: true, message: 'First name is required!' }]}
                                            >
                                                <Input placeholder="" size="large" autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name="lastName"
                                                label="Last name"
                                                hasFeedback
                                                rules={[{ required: true, message: 'Last name is required!' }]}
                                            >
                                                <Input placeholder="" size="large" autoComplete="off" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        name="email"
                                        label="Email address"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Email address is required!' }]}
                                    >
                                        <Input placeholder="example@email.com" size="large" autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        label="Create a password"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Password is required!' }]}
                                    >
                                        <Input.Password placeholder="********" onChange={e => setPassword(e.target.value)} size="large" autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item
                                        name="confirmPassword"
                                        label="Confirm your password"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Password confirm is required!' }]}
                                    >
                                        <Input.Password placeholder="********" onChange={e => setConfirmPassword(e.target.value)} size="large" autoComplete="off" />
                                    </Form.Item>

                                    <PasswordChecklist
                                        minLength={8}
                                        value={password}
                                        valueAgain={confirmPassword}
                                        iconComponents={{ ValidIcon, InvalidIcon }}
                                        onChange={(isValid) => setIsPasswordValid(isValid)}
                                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                                    />

                                    <br />

                                    <Form.Item
                                        name="terms"
                                        label=""
                                        hasFeedback
                                        valuePropName="checked"
                                        rules={[{ required: true, message: 'Please accept our terms & conditions and privacy policy' }]}
                                    >
                                        <Row gutter={[12, 0]}>
                                            <Col>
                                                <Checkbox />
                                            </Col>
                                            <Col lg={22} md={22} sm={20} xs={20}>
                                                <p>
                                                    I have read and agree to the <Link to="/terms/conditions">Terms & Conditions</Link> as well as
                                                    the <Link to="/privacy/policy">Privacy Policy</Link> presented by Pesawise Limited.
                                                </p>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                    <br />
                                    <Form.Item>
                                        <Button
                                            block
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                            loading={status === 'pending'}
                                        >
                                            Create my account
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xl={8} lg={0} md={0} sm={0} xs={0}>
                    <Card bordered={false} className="register__banner">
                        <Row gutter={[20, 0]} justify="end" align="middle">
                            <Col>
                                <p className="register__banner__text">
                                    Already have an account?
                                </p>
                            </Col>
                            <Col>
                                <Button className="register__btn" onClick={goToLogin} size="large">
                                    Sign In
                                </Button>
                            </Col>
                        </Row>

                        <div className="register__banner__container">
                            <AuthVector />
                        </div>
                    </Card>
                </Col>
            </Row>
        </section>
    )
}
export default SignUp;
