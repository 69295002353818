import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {ILocale, ILocaleState, IRequestError} from './locale-slice-types';

export const getLocales = createAsyncThunk<
    ILocale[],
    undefined,
    {rejectValue: IRequestError}
>(
    "LOCALES", async (_, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/public-api/get-active-locales`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const localeSlice = createSlice({
    name: 'locales',
    initialState: {
        localeStatus: 'idle',
        locales: [],
    } as ILocaleState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLocales.pending, (state, _) => {
            state.localeStatus = 'pending';
        })
        builder.addCase(getLocales.fulfilled, (state, {payload}) => {
            state.locales = payload;
            state.localeStatus = 'succeeded';
        })
        builder.addCase(getLocales.rejected, (state, {payload, error}) => {
            state.locales = [];
            state.localeStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default localeSlice.reducer;
