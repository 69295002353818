import React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Row, Col, Card, Form, Input, Button, message} from 'antd';

import './styles.less';
import Logo from '../../components/ui/logo';
import AuthVector from '../../components/ui/vectors/auth';

import {forgotPassword} from '../../redux/auth/auth-slice';
import {useAppSelector, useAppDispatch} from '../../hooks/redux';

interface IProps{
    history: RouteComponentProps["history"];
    match: RouteComponentProps["match"];
}

const PasswordForgot:React.FC<IProps> = ({history}):JSX.Element => {
    const {status} = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();

    const onFinish = async (values: any):Promise<any> => {
        const {error}:any = await dispatch(forgotPassword(values));
        if(!error){
            form.resetFields();
            message.success('Instructions to reset your password has been sent to your email address');
        }
    }

    const goToRegister = ():void => history.push('/register');

    return (
        <section className="pf">
            <Row justify="center">
                <Col xl={16} lg={24} md={24} sm={24} xs={24}>
                    <Card bordered={false} className="pf__form">
                        <Logo/>
                        <Row className="pf__form__row" justify="center" align='middle'>
                            <Col xl={10} lg={9} md={12} sm={16} xs={22}>
                                <h1 className="pf__form__heading">
                                    Get new password
                                </h1>

                                <p className="pf__form__text --space-bottom">
                                    We'll send a link to your email address to set 
                                    up your new password. Follow the instructions outlined there to reset
                                    your account's password.
                                </p>

                                <Form
                                    form={form}
                                    name="password_forgot"
                                    initialValues={{}}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <Form.Item
                                        name="email"
                                        label="Email address"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Email address is required!' }]}
                                    >
                                        <Input placeholder="example@email.com" size="large" autoComplete="off" />
                                    </Form.Item>

                                    <p className="pf__form__link">
                                       Trouble resetting your password? <span>Contact us</span>
                                    </p>

                                    <Form.Item className="pf__space-top">
                                        <Button 
                                            block
                                            size="large"
                                            type="primary" 
                                            htmlType="submit" 
                                            loading={status === 'pending'}
                                        >
                                            Continue
                                        </Button>
                                    </Form.Item>

                                    <Link className="pf__link" to="/">
                                       &larr; Go back
                                    </Link>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xl={8} lg={0} md={0} sm={0} xs={0}>
                    <Card bordered={false} className="pf__banner">
                        <Row gutter={[20, 0]} justify="end" align="middle">
                            <Col>
                                <p className="pf__banner__text">
                                    Don't have an account?
                                </p>
                            </Col>
                            <Col>
                                <Button onClick={goToRegister} size="large">
                                    Sign up
                                </Button>    
                            </Col>
                        </Row>

                        <div className="pf__banner__container">
                            <AuthVector as="password"/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </section>
    )
}

export default PasswordForgot;
