import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {ICompliance, IBankPayload, IBusinessPayload, IMobilePayload, IComplianceState, IResponse, IRequestError} from './compliance-slice-types';

export const getCompliances = createAsyncThunk<
    ICompliance[],
    number,
    {rejectValue: IRequestError}
>(
    "COMPLIANCE/LIST", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/compliance/get-compliance-status?businessId=${businessId}`, config);
            return res.data.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getBusinessDocs = createAsyncThunk<
    any,
    number,
    {rejectValue: IRequestError}
>(
    "COMPLIANCE/DOCS", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/compliance/get-document-info2?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const uploadBankInfo = createAsyncThunk<
    IResponse,
    IBankPayload,
    {rejectValue: IRequestError}
>(
    "COMPLIANCE/BANK", async (bankInfo, thunkApi) => {
        const payload = {...bankInfo};
        delete payload.businessId;

        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/compliance/upload-bank-details?businessId=${bankInfo.businessId}`, payload, config);
            return res.data.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const uploadBusinessInfo = createAsyncThunk<
    IResponse,
    IBusinessPayload,
    {rejectValue: IRequestError}
>(
    "COMPLIANCE/BUSINESS", async (businessInfo, thunkApi) => {
        const payload = {...businessInfo};
        delete payload.businessId;

        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/compliance/upload-business-info?businessId=${businessInfo.businessId}`, payload, config);
            return res.data.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const uploadMobileInfo = createAsyncThunk<
    IResponse,
    IMobilePayload,
    {rejectValue: IRequestError}
>(
    "COMPLIANCE/MOBILE", async (mobileInfo, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-user-util/verify-phone-number-initiate?phoneNumber=${mobileInfo.phoneNumber}`, config);
            return res.data.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const verifyMobileInfo = createAsyncThunk<
    IResponse,
    string,
    {rejectValue: IRequestError}
>(
    "COMPLIANCE/VERIFY-MOBILE", async (token, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-user-util/verify-phone-number?token=${token}`, config);
            return res.data.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const complianceSlice = createSlice({
    name: 'compliance',
    initialState: {
        status: 'idle',
        uploading: 'idle',
        verifying: 'idle',
        compliances: [],
        docs: [],
        error: '',
    } as IComplianceState,
    reducers: {},
    extraReducers: (builder) => {
        //@: get all compliances reducer
        builder.addCase(getCompliances.pending, (state, _) => {
            state.status = 'pending';
            state.uploading = 'idle';
            state.verifying = 'idle';
            state.error = '';
        })
        builder.addCase(getCompliances.fulfilled, (state, {payload}) => {
            state.compliances = payload;
            state.status = 'succeeded';
        })
        builder.addCase(getCompliances.rejected, (state, {payload, error}) => {
            state.compliances = [];
            state.status = 'failed';
            state.error = payload ? payload.detail : error.message;
        })

        //@: get all uploaded business documents reducer
        builder.addCase(getBusinessDocs.pending, (state, _) => {
            state.status = 'pending';
        })
        builder.addCase(getBusinessDocs.fulfilled, (state, {payload}) => {
            state.docs = payload;
            state.status = 'succeeded';
        })
        builder.addCase(getBusinessDocs.rejected, (state, {payload, error}) => {
            state.docs = [];
            state.status = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: upload bank info reducer
        builder.addCase(uploadBankInfo.pending, (state, _) => {
            state.uploading = 'pending';
        })
        builder.addCase(uploadBankInfo.fulfilled, (state, _) => {
            state.uploading = 'succeeded';
        })
        builder.addCase(uploadBankInfo.rejected, (state, {payload, error}) => {
            state.uploading = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: upload business info reducer
        builder.addCase(uploadBusinessInfo.pending, (state, _) => {
            state.uploading = 'pending';
        })
        builder.addCase(uploadBusinessInfo.fulfilled, (state, _) => {
            state.uploading = 'succeeded';
        })
        builder.addCase(uploadBusinessInfo.rejected, (state, {payload, error}) => {
            state.uploading = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: upload mobile info reducer
        builder.addCase(uploadMobileInfo.pending, (state, _) => {
            state.uploading = 'pending';
        })
        builder.addCase(uploadMobileInfo.fulfilled, (state, _) => {
            state.uploading = 'succeeded';
        })
        builder.addCase(uploadMobileInfo.rejected, (state, {payload, error}) => {
            state.uploading = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: verify mobile info reducer
        builder.addCase(verifyMobileInfo.pending, (state, _) => {
            state.verifying = 'pending';
        })
        builder.addCase(verifyMobileInfo.fulfilled, (state, _) => {
            state.verifying = 'succeeded';
        })
        builder.addCase(verifyMobileInfo.rejected, (state, {payload, error}) => {
            state.verifying = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default complianceSlice.reducer;
