import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {ICurrency, TCurrencyState, IRequestError} from './currency-slice-types';

export const getCurrency = createAsyncThunk<
    ICurrency[],
    number,
    {rejectValue: IRequestError}
>(
    "CURRENCY/LIST", async (localeId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-util/get-currencies?localeId=${localeId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const currencySlice = createSlice({
    name: 'currency',
    initialState: {
        currencyStatus: 'idle',
        currencyList: [],
    } as TCurrencyState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCurrency.pending, (state, _) => {
            state.currencyStatus = 'pending';
        })
        builder.addCase(getCurrency.fulfilled, (state, {payload}) => {
            state.currencyList = payload;
            state.currencyStatus = 'succeeded';
        })
        builder.addCase(getCurrency.rejected, (state, {payload, error}) => {
            state.currencyList = [];
            state.currencyStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default currencySlice.reducer;
