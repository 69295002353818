import axios from 'axios';

export const BASE_URL:string = 
    process.env.REACT_APP_API_BASE_URL === 'development' ? 
        'https://eapi.pesawise.xyz/api' : 
    process.env.REACT_APP_API_BASE_URL === 'production' ? 
        'https://eapi.pesawise.com/api' : 
        'https://eapi.pesawise.xyz/api'

export const axiosRequest = axios.create({
    baseURL: BASE_URL,
    headers: {}
});
