import logo from '../assets/images/logo.png';
import logo_icon from '../assets/images/logo-icon.png';
import logo_icon_muted from '../assets/images/logo-icon-3.png';
import user__icon from '../assets/icons/user-icon.png';
import bank__icon from '../assets/icons/bank-icon.png';
import business__icon from '../assets/icons/business-icon.png';

import dashboard from '../assets/images/home.png';
import transactions from '../assets/images/transactions.png';
import transfers from '../assets/images/transfers.png';
import counterparty from '../assets/images/counterparty.png';
import invoices from '../assets/images/invoices.png';
import payment_links from '../assets/images/payment-links.png';
import pos from '../assets/images/pos.png';
import unauthorized from '../assets/images/unauthorized-2.png';
import referrals from '../assets/images/referrals.png';
import chargeback from '../assets/images/chargeback.png';
import error from '../assets/images/error.png';
import not_found from '../assets/images/404.png';
import offline from '../assets/images/offline.png';
import avatar_male from '../assets/images/avatar-male.jpg';
import avatar_female from '../assets/images/avatar-female.jpg';

const IMAGES = {
    logo,
    logo_icon,
    logo_icon_muted,
    user__icon,
    bank__icon,
    business__icon,

    dashboard,
    transactions,
    transfers,
    counterparty,
    invoices,
    payment_links,
    pos,
    unauthorized,
    referrals,
    chargeback,
    error,
    not_found,
    offline,
    avatar_female,
    avatar_male,
};

export default IMAGES;