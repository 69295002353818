import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {ITransactions, ITransactionResponse, TransactionParams, ITransactionState, IRequestError} from './transactions-slice-types';

export const getTransactions = createAsyncThunk<
    ITransactionResponse,
    TransactionParams,
    {rejectValue: IRequestError}
>(
    "TRANSACTIONS/LIST", async (params, thunkApi) => {
        try {
            const config = {headers: {
                "page-number": params.pageNumber || '1',
                "page-size": params.pageSize || '20',
                "start-date": params.startDate || '',
                "end-date": params.endDate || '',
                "payment-type": params.paymentType || '',
                "payment-status": params.paymentStatus || '',
                "balances": params.balances || '',
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-transaction/transactions?businessId=${params.businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getTransaction = createAsyncThunk<
    ITransactions,
    number,
    {rejectValue: IRequestError}
>(
    "TRANSACTIONS/ITEM", async (transactionId, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-transaction/transaction?id=${transactionId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const filterTransactionStatus = createAsyncThunk<
    string[],
    undefined,
    {rejectValue: IRequestError}
>(
    "TRANSACTIONS/STATUS", async (_, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.get(`/business-transaction/filter-metadata-status`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getTransactionMetaTypes = createAsyncThunk<
    string[],
    undefined,
    {rejectValue: IRequestError}
>(
    "TRANSACTIONS/META-TYPES", async (_, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.get(`/business-transaction/filter-metadata-transaction-type`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const downloadTransaction = createAsyncThunk<
    string[],
    number,
    {rejectValue: IRequestError}
>(
    "TRANSACTION/DOWNLOAD", async (transactionId, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json',
            }};
            const res = await axiosRequest.get(`/business-transaction/transaction-download?id=${transactionId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const downloadTransactions = createAsyncThunk<
    string[],
    {businessId: number; startDate: string; endDate:string; balances:string;},
    {rejectValue: IRequestError}
>(
    "TRANSACTIONS/DOWNLOAD", async (params, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json',
                'start-date': params.startDate,
                'end-date': params.endDate,
                'balances': params.balances
            }};
            const res = await axiosRequest.get(`/business-transaction/transaction-download-bulk?businessId=${params.businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const transactionSlice = createSlice({
    name: 'transactions',
    initialState: {
        status: 'idle',
        filterStatus: 'idle',
        metaStatus: 'idle',
        fetching: 'idle',
        downloading: 'idle',
        transactions: null,
        transaction: null,
        filters: [],
        metaTypes: [],
        totalCount: 0,
        totalPages: 0,
        error: '',
    } as ITransactionState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTransactions.pending, (state, _) => {
            state.status = 'pending';
        })
        builder.addCase(getTransactions.fulfilled, (state, {payload}) => {
            state.transactions = payload.data;
            state.totalPages = payload.totalPages;
            state.totalCount = payload.totalElements;
            state.status = 'succeeded';
        })
        builder.addCase(getTransactions.rejected, (state, {payload, error}) => {
            state.transactions = null;
            state.totalCount = 0;
            state.totalPages = 0;
            state.status = 'failed';
            state.error = payload ? payload.detail : error.message;
        })

        //@: get transactions filters statuses reducer
        builder.addCase(filterTransactionStatus.pending, (state, _) => {
            state.filterStatus = 'pending';
        })
        builder.addCase(filterTransactionStatus.fulfilled, (state, {payload}) => {
            state.filterStatus = 'succeeded';
            state.filters = payload
        })
        builder.addCase(filterTransactionStatus.rejected, (state, {payload, error}) => {
            state.filterStatus = 'failed';
            state.filters = [];
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get transactions meta types reducer
        builder.addCase(getTransactionMetaTypes.pending, (state, _) => {
            state.metaStatus = 'pending';
        })
        builder.addCase(getTransactionMetaTypes.fulfilled, (state, {payload}) => {
            state.metaStatus = 'succeeded';
            state.metaTypes = payload
        })
        builder.addCase(getTransactionMetaTypes.rejected, (state, {payload, error}) => {
            state.metaStatus = 'failed';
            state.metaTypes = [];
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get transaction reducer
        builder.addCase(getTransaction.pending, (state, _) => {
            state.fetching = 'pending';
        })
        builder.addCase(getTransaction.fulfilled, (state, {payload}) => {
            state.fetching = 'succeeded';
            state.transaction = payload
        })
        builder.addCase(getTransaction.rejected, (state, {payload, error}) => {
            state.fetching = 'failed';
            state.transaction = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: download transaction reducer
        builder.addCase(downloadTransactions.pending, (state, _) => {
            state.downloading = 'pending';
        })
        builder.addCase(downloadTransactions.fulfilled, (state, _) => {
            state.downloading = 'succeeded';
        })
        builder.addCase(downloadTransactions.rejected, (state, {payload, error}) => {
            state.downloading = 'failed';
            state.transaction = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default transactionSlice.reducer;
