import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {IBalance, TBalanceState, IRequestError, IAccount} from './balance-slice-types';

export const getBalances = createAsyncThunk<
    IBalance[],
    number,
    {rejectValue: IRequestError}
>(
    "BALANCES/LIST", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-balance/balances?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getAccountBalance = createAsyncThunk<
    IAccount[],
    {businessId: number; balanceId: number;},
    {rejectValue: IRequestError}
>(
    "BALANCES/ACCOUNT", async (params, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-balance/top-up?businessId=${params.businessId}&balanceId=${params.balanceId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const balanceSlice = createSlice({
    name: 'balances',
    initialState: {
        balanceStatus: 'idle',
        accountStatus: 'idle',
        balances: [],
        account: [],
        error: '',
    } as TBalanceState,
    reducers: {
        resetAccountStatus: (state) => {
            state.accountStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        //@: get all balances reducer
        builder.addCase(getBalances.pending, (state, _) => {
            state.balanceStatus = 'pending';
        })
        builder.addCase(getBalances.fulfilled, (state, {payload}) => {
            state.balances = payload;
            state.balanceStatus = 'succeeded';
        })
        builder.addCase(getBalances.rejected, (state, {payload, error}) => {
            state.balances = [];
            state.balanceStatus = 'failed';
            state.error = payload ? payload.detail : error.message;
        })

        //@: get account balance reducer
        builder.addCase(getAccountBalance.pending, (state, _) => {
            state.accountStatus = 'pending';
        })
        builder.addCase(getAccountBalance.fulfilled, (state, {payload}) => {
            state.account = payload;
            state.accountStatus = 'succeeded';
        })
        builder.addCase(getAccountBalance.rejected, (state, {payload, error}) => {
            state.account = [];
            state.accountStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export const {resetAccountStatus} = balanceSlice.actions;

export default balanceSlice.reducer;
