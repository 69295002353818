import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {IConnectedApp, IConnectedAppState, IRequestError} from './connect-slice-types';

export const getConnectedApps = createAsyncThunk<
    IConnectedApp[],
    number,
    {rejectValue: IRequestError}
>(
    "CONNECTED_APPS", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/connected-apps/apps?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getConnectedApp = createAsyncThunk<
    IConnectedApp,
    {businessId: number; slug: string | undefined},
    {rejectValue: IRequestError}
>(
    "CONNECTED_APP", async (params, thunkApi) => {
        const {businessId, slug} = params;
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/connected-apps/get-app-by-slug?businessId=${businessId}&slug=${slug}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const connectSlice = createSlice({
    name: 'connected_apps',
    initialState: {
        connectStatus: 'idle',
        apps: [],
        app: null,
        categories: [],
        error: '',
    } as IConnectedAppState,
    reducers: {},
    extraReducers: (builder) => {
        //@: get all connected apps reducers
        builder.addCase(getConnectedApps.pending, (state, _) => {
            state.connectStatus = 'pending';
            state.error = '';
        })
        builder.addCase(getConnectedApps.fulfilled, (state, {payload}) => {
            state.apps = payload;
            state.categories = ['ALL APPLICATIONS', ...new Set(payload.map(item => item.appType))];
            state.connectStatus = 'succeeded';
        })
        builder.addCase(getConnectedApps.rejected, (state, {payload, error}) => {
            state.apps = [];
            state.connectStatus = 'failed';
            state.error = payload ? payload : error.message;
        })

        //@: get connect app reducers
        builder.addCase(getConnectedApp.pending, (state, _) => {
            state.connectStatus = 'pending';
        })
        builder.addCase(getConnectedApp.fulfilled, (state, {payload}) => {
            state.app = payload;
            state.connectStatus = 'succeeded';
        })
        builder.addCase(getConnectedApp.rejected, (state, {payload, error}) => {
            state.apps = [];
            state.connectStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default connectSlice.reducer;
