import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {IInvoiceResponse, TInvoiceState, IInvoicePayload, ICInvoicePayload, IRequestError, IInvoice} from './invoices-slice-types';

export const getInvoices = createAsyncThunk<
    IInvoiceResponse,
    IInvoicePayload,
    {rejectValue: IRequestError}
>(
    "INVOICES/LIST", async (params, thunkApi) => {
        try {
            const config = {headers: {
                'page-number': params.pagenumber,
                'page-size': params.pagesize,
                'start-date': params.startdate || '',
                'end-date': params.enddate || '',
                'statuses': params.statuses || '',
            }};
            const res = await axiosRequest.get(`/business-invoices/invoices?businessId=${params.businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getInvoice = createAsyncThunk<
    IInvoice,
    {businessId: number; invoiceId: number;},
    {rejectValue: IRequestError}
>(
    "INVOICES/ITEM", async (params, thunkApi) => {
        try {
            const res = await axiosRequest.get(`/business-invoices/invoice?businessId=${params.businessId}&id=${params.invoiceId}`);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const filterInvoiceStatus = createAsyncThunk<
    string[],
    undefined,
    {rejectValue: IRequestError}
>(
    "INVOICE/STATUS", async (_, thunkApi) => {
        try {
            const res = await axiosRequest.get(`/business-invoices/filter-metadata-status`);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const createInvoice = createAsyncThunk<
    {invoiceId: number; message: string;},
    ICInvoicePayload,
    {rejectValue: IRequestError}
>(
    "INVOICES/CREATE", async (params, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const invoice = Object.assign({}, params);
            delete invoice.businessId;

            const res = await axiosRequest.post(`/business-invoices/create-invoice?businessId=${params.businessId}`, invoice, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const markInvoiceAsPaid = createAsyncThunk<
    undefined,
    {businessId: number; id: number;},
    {rejectValue: IRequestError}
>(
    "INVOICES/MARK_AS_PAID", async (params, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.get(`/business-invoices/mark-paid?businessId=${params.businessId}&id=${params.id}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const unMarkInvoiceAsPaid = createAsyncThunk<
    undefined,
    {businessId: number; id: number;},
    {rejectValue: IRequestError}
>(
    "INVOICES/UNMARK_AS_PAID", async (params, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.get(`/business-invoices/mark-unpaid?businessId=${params.businessId}&id=${params.id}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const invoiceSlice = createSlice({
    name: 'invoices',
    initialState: {
        status: 'idle',
        creating: 'idle',
        fetching: 'idle',
        marking: 'idle',
        filterStatus: 'idle',
        invoices: null,
        invoice: null,
        filters: [],
        totalCount: 0,
        totalElements: 0,
        error: '',
    } as TInvoiceState,
    reducers: {},
    extraReducers: (builder) => {
        //@: get invoices reducer
        builder.addCase(getInvoices.pending, (state, _) => {
            state.status = 'pending';
            state.error = '';
        })
        builder.addCase(getInvoices.fulfilled, (state, {payload}) => {
            state.invoices = payload.data;
            state.totalElements = payload.totalElements;
            state.totalCount = payload.totalPages;
            state.status = 'succeeded';
        })
        builder.addCase(getInvoices.rejected, (state, {payload, error}) => {
            state.invoices = null;
            state.totalElements = 0;
            state.totalCount = 0;
            state.status = 'failed';
            state.error = payload ? payload.detail : error.message;
        })

        //@: get invoice reducer
        builder.addCase(getInvoice.pending, (state, _) => {
            state.fetching = 'pending';
        })
        builder.addCase(getInvoice.fulfilled, (state, {payload}) => {
            state.invoice = payload;
            state.fetching = 'succeeded';
        })
        builder.addCase(getInvoice.rejected, (state, {payload, error}) => {
            state.invoice =null;
            state.fetching = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get invoice filter status reducer
        builder.addCase(filterInvoiceStatus.pending, (state, _) => {
            state.filterStatus = 'pending';
        })
        builder.addCase(filterInvoiceStatus.fulfilled, (state, {payload}) => {
            state.filters = payload;
            state.filterStatus = 'succeeded';
        })
        builder.addCase(filterInvoiceStatus.rejected, (state, {payload, error}) => {
            state.filters = [];
            state.filterStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: create invoices reducer
        builder.addCase(createInvoice.pending, (state, _) => {
            state.creating = 'pending';
        })
        builder.addCase(createInvoice.fulfilled, (state, _) => {
            state.creating = 'succeeded';
            message.success('Invoice created successully.')
        })
        builder.addCase(createInvoice.rejected, (state, {payload, error}) => {
            state.creating = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: mark invoices as paid reducer
        builder.addCase(markInvoiceAsPaid.pending, (state, _) => {
            state.marking = 'pending';
        })
        builder.addCase(markInvoiceAsPaid.fulfilled, (state, _) => {
            state.marking = 'succeeded';
        })
        builder.addCase(markInvoiceAsPaid.rejected, (state, {payload, error}) => {
            state.marking = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: unmark invoices as paid reducer
        builder.addCase(unMarkInvoiceAsPaid.pending, (state, _) => {
            state.marking = 'pending';
        })
        builder.addCase(unMarkInvoiceAsPaid.fulfilled, (state, _) => {
            state.marking = 'succeeded';
        })
        builder.addCase(unMarkInvoiceAsPaid.rejected, (state, {payload, error}) => {
            state.marking = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default invoiceSlice.reducer;
