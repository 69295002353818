import React from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';
import {message} from 'antd';

//@: Local fonts
import './assets/fonts/Gilroy-Light.otf';
import './assets/fonts/Gilroy-Regular.otf';
import './assets/fonts/Gilroy-Semibold.otf';
import './assets/fonts/Gilroy-Bold.otf';

//@: Pages
import SignIn from './pages/signin';
import SignUp from './pages/signup';


import PasswordReset from './pages/password-reset';
import PasswordForgot from './pages/password-forgot';
import EmailVerification from './pages/email-verification';
import NotFound from './pages/404';

//@: utils, components, helpers, hooks etc..
import {axiosRequest} from './helpers/request';
import {logoutUser} from './redux/auth/auth-slice';
import Loading from './components/utility/loading';
import ScrollRestoration from './utils/scrollRestore';
import AuthRoute from './components/utility/auth-route';
import {useAppSelector, useAppDispatch} from './hooks/redux';


//@: lazily loaded routes
const Home = React.lazy(() => import('./pages/home'));
const GetStarted = React.lazy(() => import('./pages/get-started'));
const Compliance = React.lazy(() => import('./pages/compliance/home'));
const ComplianceBank = React.lazy(() => import('./pages/compliance/bank'));
const ComplianceMobile = React.lazy(() => import('./pages/compliance/mobile'));
const ComplianceBusiness = React.lazy(() => import('./pages/compliance/business'));
const ComplianceDocuments = React.lazy(() => import('./pages/compliance/documents'));

const Balances = React.lazy(() => import('./pages/balances'));
const Transactions = React.lazy(() => import('./pages/transactions'));
const ListTransfers = React.lazy(() => import('./pages/transfers/list'));
const BankTransfers = React.lazy(() => import('./pages/transfers/bank'));
const ResendTransfer = React.lazy(() => import('./pages/transfers/resend'));
const MobileTransfers = React.lazy(() => import('./pages/transfers/mobile'));
const BusinessTransfers = React.lazy(() => import('./pages/transfers/business'));

const PayBills = React.lazy(() => import('./pages/bills/pay'));
const ListBills = React.lazy(() => import('./pages/bills/list'));
const ListTeams = React.lazy(() => import('./pages/teams/list')); 
const CreateTeams = React.lazy(() => import('./pages/teams/create')); 

const Referrals = React.lazy(() => import('./pages/referrals')); 
//const ChargeBack = React.lazy(() => import('./pages/chargeback')); 
const PaymentLinks = React.lazy(() => import('./pages/payment-links/list')); 
const CreatePaymentLinks = React.lazy(() => import('./pages/payment-links/create')); 

const Invoices = React.lazy(() => import('./pages/invoices/list')); 
const CreateInvoices = React.lazy(() => import('./pages/invoices/create')); 

const POS = React.lazy(() => import('./pages/pos')); 
const Settings = React.lazy(() => import('./pages/settings')); 
const Connect = React.lazy(() => import('./pages/connect/list')); 
const ConnectDetails = React.lazy(() => import('./pages/connect/details')); 


const App:React.FC = ():JSX.Element => {
  const {user} = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();

  //@: set token for authenticated requests
  if(user){
    axiosRequest.defaults.headers.common['Authorization'] = `Bearer ${user?.jwtToken}`;
  }
  else{
    delete axiosRequest.defaults.headers.common["Authorization"];
  }

  //@: intercept expired tokens and logout user automatically
  axiosRequest.interceptors.response.use(
    function(response){
      return response;
    },
    function(error){
      let res = error.response;
      const infoStatus = res.data?.detail;
      if((res.status === 401 && infoStatus === "INVALID_TOKEN") && (res.config && !res.config.__isRetryRequest)){
        return new Promise(() => {
          dispatch(logoutUser());
          message.error('Your session has expired. Please sign in to continue')
        });
      }
      return Promise.reject(error);
    }
  )
  
  return (
    <React.Fragment>
      <ScrollRestoration/>

      <React.Suspense fallback={<Loading/>}>
        <Switch>
          <Redirect exact from="/" to="/login"/>
          <Route path="/login" component={SignIn}/>
          <Route path="/register" component={SignUp}/>

          <AuthRoute path="/get-started" component={GetStarted}/>
          <AuthRoute path="/home" component={Home}/>

          <AuthRoute path="/compliance" exact component={Compliance}/>
          <AuthRoute path="/compliance/bank" component={ComplianceBank}/>
          <AuthRoute path="/compliance/business" component={ComplianceBusiness}/>
          <AuthRoute path="/compliance/documents" component={ComplianceDocuments}/>
          <AuthRoute path="/compliance/mobile" component={ComplianceMobile}/>

          <AuthRoute path="/balances" component={Balances}/>

          <AuthRoute path="/transactions" component={Transactions}/>

          <AuthRoute path="/transfers" exact component={ListTransfers}/>
          <AuthRoute path="/transfers/bank" component={BankTransfers}/>
          <AuthRoute path="/transfers/business" component={BusinessTransfers}/>
          <AuthRoute path="/transfers/mobile" component={MobileTransfers}/>
          <AuthRoute path="/transfers/resend/:slug" component={ResendTransfer} />

          <AuthRoute path="/bills" exact component={ListBills}/>
          <AuthRoute path="/bills/pay/:slug" component={PayBills}/>

          <AuthRoute exact path="/teams" component={ListTeams}/>
          <AuthRoute path="/teams/create" component={CreateTeams}/>

          <AuthRoute path="/referrals" component={Referrals}/>
          
          {/* <AuthRoute path="/chargeback" component={ChargeBack}/> */}

          <AuthRoute path="/payment/links" exact component={PaymentLinks}/>
          <AuthRoute path="/payment/links/create" component={CreatePaymentLinks}/>

          <AuthRoute path="/invoices" exact component={Invoices}/>
          <AuthRoute path="/invoices/create" component={CreateInvoices}/>
          
          <AuthRoute path="/pos" component={POS}/>

          <AuthRoute path="/connect" exact component={Connect}/>
          <AuthRoute path="/connect/:slug" component={ConnectDetails}/>

          <AuthRoute path="/settings" component={Settings}/>

          <Route path="/password/reset" component={PasswordReset}/>
          <Route path="/password/forgot" component={PasswordForgot}/>
          <Route path="/email/verification" component={EmailVerification}/>
          <Route path="*" component={NotFound}/>
        </Switch>
      </React.Suspense>
    </React.Fragment>
  )
}

export default App;
