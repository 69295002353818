import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';

import {axiosRequest} from '../../helpers/request';
import {IDashboard, IDashboardState, IRequestError} from './dashboard-slice-types';

export const getDashboardInfo = createAsyncThunk<
    IDashboard,
    number,
    {rejectValue: IRequestError}
>(
    "DASHBOARD", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/home/summary?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const dashboardSlice = createSlice({
    name: 'locales',
    initialState: {
        status: 'idle',
        items: null,
        error: '',
    } as IDashboardState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDashboardInfo.pending, (state, _) => {
            state.status = 'pending';
            state.error = '';
        })
        builder.addCase(getDashboardInfo.fulfilled, (state, {payload}) => {
            state.items = payload;
            state.status = 'succeeded';
        })
        builder.addCase(getDashboardInfo.rejected, (state, {payload, error}) => {
            state.items = null;
            state.status = 'failed';
            state.error = payload ? payload.detail : error.message;
        })
    },
});

export default dashboardSlice.reducer;
