import React from 'react';
import {Row, Col} from 'antd';

import './styles.less';

const ConfirmVector:React.FC = () => {
    return (
        <div className="confirm__vector">
            <div className="confirm__vector__circle"/>

            <div className="confirm__vector__square">
                <h3>Pesawise</h3>
                <Row gutter={[12, 0]}>
                    <Col>
                        <div className="confirm__vector__square__line"/>
                    </Col>
                    <Col>
                        <div className="confirm__vector__square__line"/>
                    </Col>
                    <Col>
                        <div className="confirm__vector__square__line --small"/>
                    </Col>
                    <Col>
                        <div className="confirm__vector__square__line --long"/>
                    </Col>
                </Row>

                <Row className="confirm__vector__square__row">
                    <Col lg={12}>
                        <Row gutter={[12, 0]}>
                            <Col>
                                <div className="confirm__vector__square__box">
                                    <div className="confirm__vector__square__box__dot"/>
                                </div>
                            </Col>
                            <Col>
                                <div className="confirm__vector__square__box">
                                    <div className="confirm__vector__square__box__dot"/>
                                </div>
                            </Col>
                            <Col>
                                <div className="confirm__vector__square__box">
                                    <div className="confirm__vector__square__box__dot"/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={12}>
                        <Row gutter={[12, 0]}>
                            <Col>
                                <div className="confirm__vector__square__box">
                                    <div className="confirm__vector__square__box__dot"/>
                                </div>
                            </Col>
                            <Col>
                                <div className="confirm__vector__square__box">
                                    <div className="confirm__vector__square__box__dot"/>
                                </div>
                            </Col>
                            <Col>
                                <div className="confirm__vector__square__box">
                                    <div className="confirm__vector__square__box__dot"/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="confirm__vector__square__index">
                    1
                </div>
            </div>
        </div>
    )
}

export default ConfirmVector
