import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {IPaymentLink, IPaymentLinkParams, IPaymentLinksResponse, IPaymentLinkPayload, TPaymentLinksState, IRequestError} from './payment-links-slice-types';

export const getPaymentLinks = createAsyncThunk<
    IPaymentLinksResponse,
    IPaymentLinkParams,
    {rejectValue: IRequestError}
>(
    "PAYMENT_LINKS/LIST", async (params, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.get(`/business-payment-links/payment-links?businessId=${params.businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getPaymentLink = createAsyncThunk<
    IPaymentLink,
    {businessId: number; id: number;},
    {rejectValue: IRequestError}
>(
    "PAYMENT_LINKS/ITEM", async (params, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.get(`/business-payment-links/payment-link?businessId=${params.businessId}&id=${params.id}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const filterPaymentLinkStatus = createAsyncThunk<
    string[],
    undefined,
    {rejectValue: IRequestError}
>(
    "PAYMENT_LINKS/STATUS", async (_, thunkApi) => {
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.get(`/business-payment-links/filter-metadata-type`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const createPaymentLink = createAsyncThunk<
    IPaymentLinksResponse,
    IPaymentLinkPayload,
    {rejectValue: IRequestError}
>(
    "PAYMENT_LINKS/CREATE", async (info, thunkApi) => {
        const paymentLink = Object.assign({}, info);
        delete paymentLink.businessId;
        try {
            const config = {headers: {
                'Content-Type':'application/json'
            }};
            const res = await axiosRequest.post(`/business-payment-links/create-links?businessId=${info.businessId}`, paymentLink, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const deletePaymentLink = createAsyncThunk<
    {id: number},
    {businessId: number; id: number;},
    {rejectValue: IRequestError}
>(
    "PAYMENT_LINKS/DELETE", async (params, thunkApi) => {
        try {
            const res = await axiosRequest.delete(`/business-payment-links/delete-payment-link?businessId=${params.businessId}&id=${params.id}`);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const paymentLinksSlice = createSlice({
    name: 'payment_links',
    initialState: {
        status: 'idle',
        creating: 'idle',
        fetching: 'idle',
        deleting: 'idle',
        filterStatus: 'idle',
        links: null,
        link: null,
        filters: [],
        totalCount: 0,
        totalElements: 0,
        error: ''
    } as TPaymentLinksState,
    reducers: {},
    extraReducers: (builder) => {
        //@: get payment links reducer
        builder.addCase(getPaymentLinks.pending, (state, _) => {
            state.status = 'pending';
            state.deleting = 'idle';
            state.error = '';
        })
        builder.addCase(getPaymentLinks.fulfilled, (state, {payload}) => {
            state.links = payload.data;
            state.totalElements = payload.totalElements;
            state.totalCount = payload.totalPages;
            state.status = 'succeeded';
        })
        builder.addCase(getPaymentLinks.rejected, (state, {payload, error}) => {
            state.links = null;
            state.totalElements = 0;
            state.totalCount = 0;
            state.status = 'failed';
            state.error = payload ? payload.detail : error.message;
        });

        //@: get payment link reducer
        builder.addCase(getPaymentLink.pending, (state, _) => {
            state.fetching = 'pending';
        })
        builder.addCase(getPaymentLink.fulfilled, (state, {payload}) => {
            state.link = payload;
            state.fetching = 'succeeded';
        })
        builder.addCase(getPaymentLink.rejected, (state, {payload, error}) => {
            state.link = null;
            state.fetching = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        });

        //@: get payment link filter statuses reducer
        builder.addCase(filterPaymentLinkStatus.pending, (state, _) => {
            state.filterStatus = 'pending';
        })
        builder.addCase(filterPaymentLinkStatus.fulfilled, (state, {payload}) => {
            state.filters = payload;
            state.filterStatus = 'succeeded';
        })
        builder.addCase(filterPaymentLinkStatus.rejected, (state, {payload, error}) => {
            state.filters = [];
            state.filterStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        });

        //@: create payment links reducer
        builder.addCase(createPaymentLink.pending, (state, _) => {
            state.creating = 'pending';
        })
        builder.addCase(createPaymentLink.fulfilled, (state, _) => {
            state.creating = 'succeeded';
        })
        builder.addCase(createPaymentLink.rejected, (state, {payload, error}) => {
            state.creating = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: delete payment links reducer
        builder.addCase(deletePaymentLink.pending, (state, _) => {
            state.deleting = 'pending';
        })
        builder.addCase(deletePaymentLink.fulfilled, (state, {payload}) => {
            state.deleting = 'succeeded';
            state.links = state.links && state.links.filter(link => link.id !== payload.id);
        })
        builder.addCase(deletePaymentLink.rejected, (state, {payload, error}) => {
            state.deleting = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default paymentLinksSlice.reducer;
