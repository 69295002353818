import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Row, Col, Card, Button, message } from 'antd';
import ReactCodeInput from 'react-verification-code-input';

import './styles.less';
import Logo from '../../components/ui/logo';
import { useQuery } from '../../hooks/useQuery';
import ConfirmVector from '../../components/ui/vectors/confirm';

import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import { sendActivationOTP, activateAccount } from '../../redux/auth/auth-slice';

interface IProps {
    history: RouteComponentProps["history"];
    match: RouteComponentProps["match"];
}

const EmailVerification: React.FC<IProps> = ({ history }): JSX.Element => {
    const { sendOtp, status } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const [otp, setOtp] = React.useState<string>('');
    const [timer, setTimer] = React.useState<number>(60);

    let query = useQuery();
    const email = query.get('verify');

    React.useEffect(() => {
        if (email) {
            dispatch(sendActivationOTP({ email }));
        }
        else history.goBack();
    }, [dispatch, history, email]);

    React.useEffect(() => {
        let interval = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer > 0) return prevTimer - 1;
                else return prevTimer;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const onResend = async (): Promise<any> => {
        const email = query.get('verify');
        if (sendOtp === 'pending') return;

        else if (timer === 0 && email) {
            const { error }: any = await dispatch(sendActivationOTP({ email }));
            if (!error) {
                setTimer(60);
                message.success('A new OTP has been sent to your email address.')
            }
        }
    }

    const onVerifyOTP = async (): Promise<any> => {
        const { error }: any = await dispatch(activateAccount(otp));
        if (!error) {
            history.push('/login');
            message.success('Account activated. Please login to your Pesawise account');
        }
    }

    const goToLogin = (): void => history.push('/');

    return (
        <section className="ev">
            <Row>
                <Col lg={16} md={24} sm={24} xs={24}>
                    <Card bordered={false} className="ev__content">
                        <Logo />
                        <Row className="ev__content__row" justify="center" align='middle'>
                            <Col xl={10} lg={14} md={12} sm={14} xs={24}>
                                <h1 className="ev__content__heading">
                                    Your confirmation code is waiting in
                                    your email box
                                </h1>

                                <p className="ev__content__text --space-bottom">
                                    Enter the 6-digit confirmation code we've sent you at {' '}
                                    <strong>{query.get("verify")}</strong> to confirm your
                                    email address
                                </p>

                                <div className="ev__content__code">
                                    <h4 className="ev__content__code__label">
                                        Enter your code here
                                    </h4>
                                    <ReactCodeInput
                                        className="ev__content__code__input"
                                        autoFocus
                                        loading={false}
                                        onChange={value => setOtp(value)}
                                    />
                                    <p className="ev__content__code__text">
                                        Didn't get any code? {sendOtp === 'pending' ? <span>Loading...</span> : <span onClick={onResend}>Resend ({timer})</span>}
                                    </p>
                                </div>

                                <Button
                                    onClick={onVerifyOTP}
                                    loading={status === 'pending'}
                                    block
                                    size="large"
                                    type="primary"
                                >
                                    Verify confirmation code
                                </Button>

                                <Link className="ev__link" to="/"> &larr; Go back</Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col lg={8} md={0}>
                    <Card bordered={false} className="ev__banner">
                        <Row gutter={[20, 0]} justify="end" align="middle">
                            <Col>
                                <p className="ev__banner__text">
                                    Already have an account?
                                </p>
                            </Col>
                            <Col>
                                <Button onClick={goToLogin} size="large">
                                    Sign In
                                </Button>
                            </Col>
                        </Row>

                        <div className="ev__banner__container">
                            <ConfirmVector />
                        </div>
                    </Card>
                </Col>
            </Row>
        </section>
    );
}

export default EmailVerification;
