import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {IMobileProvider, IProviderState, IRequestError} from './providers-slice-types';

export const getMobileProviders = createAsyncThunk<
    IMobileProvider[],
    number,
    {rejectValue: IRequestError}
>(
    "MOBILE-PROVIDERS/LIST", async (localeId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-util/get-mobile-money-providers?localeId=${localeId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const providerSlice = createSlice({
    name: 'locales',
    initialState: {
        providerStatus: 'idle',
        mobiles: [],
    } as IProviderState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMobileProviders.pending, (state, _) => {
            state.providerStatus = 'pending';
        })
        builder.addCase(getMobileProviders.fulfilled, (state, {payload}) => {
            state.mobiles = payload;
            state.providerStatus = 'succeeded';
        })
        builder.addCase(getMobileProviders.rejected, (state, {payload, error}) => {
            state.mobiles = [];
            state.providerStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default providerSlice.reducer;
