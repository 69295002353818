const tag:string = '@pesawise'

export const saveToStorage = (key: string, value: any):void => {
    const res = JSON.stringify(value);
    localStorage.setItem(`${tag}-${key}`, res);
}

export const getFromStorage = (key: string):any => {
    const res = localStorage.getItem(`${tag}-${key}`);
    if(res) return JSON.parse(res);
    else return null;
}

export const removeFromStorage = (key: string):void => {
    localStorage.removeItem(`${tag}-${key}`);
}