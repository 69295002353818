import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {saveToStorage, getFromStorage, removeFromStorage} from '../../helpers/storage';
import {IAuthResponse, IAuthPayload, IRegResponse, IRegPayload, TAuthState, IRequestError} from './auth-slice-types';

const AUTH_KEY = 'user';
const CRED_KEY = 'remember-me';

export const loginUser = createAsyncThunk<
    IAuthResponse,
    IAuthPayload,
    {rejectValue: IRequestError}
>(
    "LOGIN/USER", async (authInfo, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/public-api/authenticate-with-data`, authInfo, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)

export const registerUser = createAsyncThunk<
    IRegResponse,
    IRegPayload,
    {rejectValue: IRequestError}
>(
    "REGISTER/USER", async (user, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-util/register-business-user`, user, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const forgotPassword = createAsyncThunk<
    undefined,
    {email: string},
    {rejectValue: IRequestError}
>(
    "FORGOT-PASSWORD/USER", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/account/reset-password/initialize`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const resetPassword = createAsyncThunk<
    undefined,
    {password: string; key: string},
    {rejectValue: IRequestError}
>(
    "RESET-PASSWORD/USER", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/account/reset-password/finish`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const changePassword = createAsyncThunk<
    undefined,
    {currentPassword: string; newPassword: string;},
    {rejectValue: IRequestError}
>(
    "CHANGE-PASSWORD/USER", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/account/change-password`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);


export const activateAccount = createAsyncThunk<
    undefined,
    string,
    {rejectValue: IRequestError}
>(
    "ACTIVATE/USER", async (otp, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/activate?key=${otp}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const sendActivationOTP = createAsyncThunk<
    {detail: string; statusCode: number;},
    {email: string},
    {rejectValue: IRequestError}
>(
    "RESEND-OTP/USER", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/public-api/get-new-email-token`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'idle',
        registered: 'idle',
        sendOtp: 'idle',
        resetting: 'idle',
        user: getFromStorage(AUTH_KEY),
        error: ''
    } as TAuthState,
    reducers: {
        logoutUser: (state) => {
            state.user = null;
            removeFromStorage(AUTH_KEY);
        },
        updateAvatar: (state, action:PayloadAction<string>) => {
            state.user = {...getFromStorage(AUTH_KEY), avatar: action.payload};
            saveToStorage(AUTH_KEY, state.user);
        },
        saveCredentials: (_, action:PayloadAction<string>) => {
            saveToStorage(CRED_KEY, action.payload);
        },
        clearCredentials: (_) => {
            removeFromStorage(CRED_KEY);
        }
    },
    extraReducers: (builder) => {
        //@: login reducers
        builder.addCase(loginUser.pending, (state, _) => {
            state.status = 'pending';
            state.error = '';
        })
        builder.addCase(loginUser.fulfilled, (state, {payload}) => {
            state.user = payload
            state.status = 'succeeded';
            saveToStorage(AUTH_KEY, payload);
        })
        builder.addCase(loginUser.rejected, (state, {payload, error}) => {
            state.user = null;
            state.status = 'failed';
            if(payload){
                state.error = payload.detail;
                message.error(payload.detail);
            }else{
                state.error = error.message;
                message.error('An error occurred. Please try again later.');
            }
        })

        //@: register reducers
        builder.addCase(registerUser.pending, (state, _) => {
            state.status = 'pending';
            state.error = '';
        })
        builder.addCase(registerUser.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.registered="succeeded";
            message.success(payload.message);
        })
        builder.addCase(registerUser.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            if(payload?.detail){
                state.error = payload.detail;
                message.error(payload.detail);
            }else{
                state.error = error.message;
                message.error(error.message)
            }
        })

        //@: activate user account reducer
        builder.addCase(activateAccount.pending, (state, _) => {
            state.status = 'pending';
        })
        builder.addCase(activateAccount.fulfilled, (state, _) => {
            state.status = 'succeeded';
        })
        builder.addCase(activateAccount.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: forgot password reducer
        builder.addCase(forgotPassword.pending, (state, _) => {
            state.status = 'pending';
        })
        builder.addCase(forgotPassword.fulfilled, (state, _) => {
            state.status = 'succeeded';
        })
        builder.addCase(forgotPassword.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: reset password reducer
        builder.addCase(resetPassword.pending, (state, _) => {
            state.status = 'pending';
        })
        builder.addCase(resetPassword.fulfilled, (state, _) => {
            state.status = 'succeeded';
        })
        builder.addCase(resetPassword.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: change password reducer
        builder.addCase(changePassword.pending, (state, _) => {
            state.resetting = 'pending';
        })
        builder.addCase(changePassword.fulfilled, (state, _) => {
            state.resetting = 'succeeded';
        })
        builder.addCase(changePassword.rejected, (state, {payload, error}) => {
            state.resetting = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: send activation OTP reducers
        builder.addCase(sendActivationOTP.pending, (state, _) => {
            state.sendOtp = 'pending';
        })
        builder.addCase(sendActivationOTP.fulfilled, (state, _) => {
            state.sendOtp = 'succeeded';
        })
        builder.addCase(sendActivationOTP.rejected, (state, {payload, error}) => {
            state.sendOtp = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export const {logoutUser, saveCredentials, clearCredentials, updateAvatar} = authSlice.actions;

export default authSlice.reducer;
