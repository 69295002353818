import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {
    TransferResponse, 
    TransferPayload, 
    ICounterparty,
    IATransfer, 
    ITransferAuth, 
    IBankTransferPayload, 
    IBusinessTransferPayload,
    IMobileTransferPayload,
    ITransferState, 
    IRequestError,
    ITransfer,
    TCounterPartyResendPayload,
} from './transfers-slice-types';

export const getPendingTransfers = createAsyncThunk<
    TransferResponse,
    TransferPayload,
    {rejectValue: IRequestError}
>(
    "TRANSFERS/PENDING", async (params, thunkApi) => {
        try {
            const config = {headers: {
                "page-number": params.pageNumber || '1',
                "page-size": params.pageSize || '20',
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-transaction/pending-transfers?businessId=${params.businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getPendingTransfer = createAsyncThunk<
    ITransfer,
    number,
    {rejectValue: IRequestError}
>(
    "TRANSFERS/PENDING_ITEM", async (transferId, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-transaction/transfer?id=${transferId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const cancelPendingTransfer = createAsyncThunk<
    {transferId: number; detail: string;},
    {id: number; businessId: number;},
    {rejectValue: IRequestError}
>(
    "TRANSFERS/CANCEL", async (params, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-transfer/cancel-transfer?transferId=${params.id}&businessId=${params.businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getCounterparties = createAsyncThunk<
    ICounterparty[],
    number,
    {rejectValue: IRequestError}
>(
    "TRANSFERS/COUNTERPARTIES", async (businessId, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-counterparties/counter-parties?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getCounterparty = createAsyncThunk<
    ICounterparty,
    {businessId: number; counterPartyId: number;},
    {rejectValue: IRequestError}
>(
    "TRANSFERS/COUNTERPARTY", async (params, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-counterparties/counter-party?businessId=${params.businessId}&counterPartyId=${params.counterPartyId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const deleteCounterparty = createAsyncThunk<
    {counterpartyId: number, detail: string;},
    {businessId: number; counterPartyId: number;},
    {rejectValue: IRequestError}
>(
    "TRANSFERS/DELETE_COUNTERPARTY", async (params, thunkApi) => {
        try {
            const res = await axiosRequest.delete(`/business-counterparties/delete-counter-party?businessId=${params.businessId}&counterPartyId=${params.counterPartyId}`);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const renameCounterparty = createAsyncThunk<
    ICounterparty,
    {businessId?: number; counterPartyId?: number; name: string;},
    {rejectValue: IRequestError}
>(
    "TRANSFERS/RENAME_COUNTERPARTY", async (params, thunkApi) => {
        const payload = {...params};
        delete payload.businessId;
        delete payload.counterPartyId;
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.put(`/business-counterparties/rename-counter-party?businessId=${params.businessId}&counterPartyId=${params.counterPartyId}`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const initiateBankTransfer = createAsyncThunk<
    IATransfer,
    IBankTransferPayload,
    {rejectValue: IRequestError}
>(
    "BANK-TRANSFER/INITIATE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/bank-transfer-initiate`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const authorizeBankTransfer = createAsyncThunk<
    ITransferAuth,
    IBankTransferPayload,
    {rejectValue: IRequestError}
>(
    "BANK-TRANSFER/AUTHORIZE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/bank-transfer`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const completeBankTransfer = createAsyncThunk<
    any,
    {transferId: number; token: string;},
    {rejectValue: IRequestError}
>(
    "BANK-TRANSFER/COMPLETE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/bank-transfer-complete?transferId=${payload.transferId}&token=${payload.token}`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const initiateBusinessTransfer = createAsyncThunk<
    IATransfer,
    IBusinessTransferPayload,
    {rejectValue: IRequestError}
>(
    "BUSINESS-TRANSFER/INITIATE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/business-transfer-initiate`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const authorizeBusinessTransfer = createAsyncThunk<
    ITransferAuth,
    IBusinessTransferPayload,
    {rejectValue: IRequestError}
>(
    "BUSINESS-TRANSFER/AUTHORIZE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/business-transfer`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const completeBusinessTransfer = createAsyncThunk<
    any,
    {transferId: number; token: string;},
    {rejectValue: IRequestError}
>(
    "BUSINESS-TRANSFER/COMPLETE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/business-transfer-complete?transferId=${payload.transferId}&token=${payload.token}`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const initiateMobileTransfer = createAsyncThunk<
    IATransfer,
    IMobileTransferPayload,
    {rejectValue: IRequestError}
>(
    "MOBILE-TRANSFER/INITIATE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/mobile-money-initiate`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const authorizeMobileTransfer = createAsyncThunk<
    ITransferAuth,
    IMobileTransferPayload,
    {rejectValue: IRequestError}
>(
    "MOBILE-TRANSFER/AUTHORIZE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/mobile-money`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const completeMobileTransfer = createAsyncThunk<
    any,
    {transferId: number; token: string;},
    {rejectValue: IRequestError}
>(
    "MOBILE-TRANSFER/COMPLETE", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-transfer/mobile-money-complete?transferId=${payload.transferId}&token=${payload.token}`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const resendTransferOTP = createAsyncThunk<
    any,
    number,
    {rejectValue: IRequestError}
>(
    "RESEND-TRANSFER/OTP", async (transferId, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.get(`/business-transfer/resend-token?transferId=${transferId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const completePendingTransfer = createAsyncThunk<
    any,
    {transferId: number; token: string;},
    {rejectValue: IRequestError}
>(
    "COMPLETE-TRANSFER/PENDING", async (params, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.post(`/business-transfer/complete-transfer?transferId=${params.transferId}&token=${params.token}`, null, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const initiateCounterPartyTransfer = createAsyncThunk<
    any,
    TCounterPartyResendPayload,
    {rejectValue: IRequestError}
>(
    "COUNTERPARTY-TRANSFER/INITIATE", async (payload, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.post(`/business-transfer/counterparty-transfer-initiate`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const authorizeCounterPartyTransfer = createAsyncThunk<
    any,
    TCounterPartyResendPayload,
    {rejectValue: IRequestError}
>(
    "COUNTERPARTY-TRANSFER/AUTHORIZE", async (payload, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.post(`/business-transfer/counterparty-transfer`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const resendCounterPartyTransfer = createAsyncThunk<
    any,
    {transferId: number; token: string;},
    {rejectValue: IRequestError}
>(
    "COUNTERPARTY-TRANSFER/RESEND", async (params, thunkApi) => {
        try {
            const config = {headers: {
                "Content-Type": "application/json"
            }};
            const res = await axiosRequest.post(`/business-transfer/counterparty-transfer-complete?transferId=${params.transferId}&token=${params.token}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const transferSlice = createSlice({
    name: 'transfers',
    initialState: {
        status: 'idle',
        fetching: 'idle',
        deleting: 'idle',
        cancelling: 'idle',
        renaming: 'idle',
        itemStatus: 'idle',
        initiateStatus: 'idle',
        authorizeStatus: 'idle',
        finalizeStatus: 'idle',
        resending: 'idle',
        completed: 'idle',
        resendTransfer: 'idle',
        summary: null,
        authSummary: null,
        pending: null,
        pendingItem: null,
        counterparties: null,
        counterparty: null,
        totalCount: 0,
        totalPages: 0,
        error:'',
    } as ITransferState,
    reducers: {
        resetAuthorizeStatus: (state) => {
            state.authorizeStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        //@: get all pending transfers reducer
        builder.addCase(getPendingTransfers.pending, (state, _) => {
            state.status = 'pending';
            state.initiateStatus = 'idle';
            state.finalizeStatus = 'idle';
            state.completed = 'idle';
            state.error = '';
        })
        builder.addCase(getPendingTransfers.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.pending = payload.data;
            state.totalCount = payload.totalElements;
            state.totalPages = payload.totalPages;
        })
        builder.addCase(getPendingTransfers.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            state.pending = null;
            state.totalCount = 0;
            state.totalPages = 0;
            state.error = payload ? payload.detail : error.message;
        })

        //@: get pending transfer item reducer
        builder.addCase(getPendingTransfer.pending, (state, _) => {
            state.itemStatus = 'pending';
        })
        builder.addCase(getPendingTransfer.fulfilled, (state, {payload}) => {
            state.itemStatus = 'succeeded';
            state.pendingItem = payload;
        })
        builder.addCase(getPendingTransfer.rejected, (state, {payload, error}) => {
            state.itemStatus = 'failed';
            state.pendingItem = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: cancel pending transfer reducer
        builder.addCase(cancelPendingTransfer.pending, (state, _) => {
            state.cancelling = 'pending';
        })
        builder.addCase(cancelPendingTransfer.fulfilled, (state, _) => {
            state.cancelling = 'succeeded';
        })
        builder.addCase(cancelPendingTransfer.rejected, (state, {payload, error}) => {
            state.cancelling = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get all counterparties transfers reducer
        builder.addCase(getCounterparties.pending, (state, _) => {
            state.status = 'pending';
            state.totalCount = 0;
            state.totalPages = 0;
            state.error = '';
        })
        builder.addCase(getCounterparties.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.counterparties = payload;
        })
        builder.addCase(getCounterparties.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            state.counterparties = null;
            state.error = payload ? payload.detail : error.message;
        })

        //@: get counterparty reducer
        builder.addCase(getCounterparty.pending, (state, _) => {
            state.fetching = 'pending';
        })
        builder.addCase(getCounterparty.fulfilled, (state, {payload}) => {
            state.fetching = 'succeeded';
            state.counterparty = payload;
        })
        builder.addCase(getCounterparty.rejected, (state, {payload, error}) => {
            state.fetching = 'failed';
            state.counterparty = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: delete counterparty reducer
        builder.addCase(deleteCounterparty.pending, (state, _) => {
            state.deleting = 'pending';
        })
        builder.addCase(deleteCounterparty.fulfilled, (state, {payload}) => {
            state.deleting = 'succeeded';
            state.counterparties = state.counterparties && state.counterparties.filter(item => item.id !== payload.counterpartyId);
        })
        builder.addCase(deleteCounterparty.rejected, (state, {payload, error}) => {
            state.deleting = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: rename counterparty reducer
        builder.addCase(renameCounterparty.pending, (state, _) => {
            state.renaming = 'pending';
        })
        builder.addCase(renameCounterparty.fulfilled, (state, {payload}) => {
            state.renaming = 'succeeded';
            state.counterparty = payload;
        })
        builder.addCase(renameCounterparty.rejected, (state, {payload, error}) => {
            state.renaming = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: initiate bank transfer reducer
        builder.addCase(initiateBankTransfer.pending, (state, _) => {
            state.initiateStatus = 'pending';
        })
        builder.addCase(initiateBankTransfer.fulfilled, (state, {payload}) => {
            state.initiateStatus = 'succeeded';
            state.summary = payload;
        })
        builder.addCase(initiateBankTransfer.rejected, (state, {payload, error}) => {
            state.initiateStatus = 'failed';
            state.summary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: authorize bank transfer reducer
        builder.addCase(authorizeBankTransfer.pending, (state, _) => {
            state.authorizeStatus = 'pending';
        })
        builder.addCase(authorizeBankTransfer.fulfilled, (state, {payload}) => {
            state.authorizeStatus = 'succeeded';
            state.authSummary = payload;
        })
        builder.addCase(authorizeBankTransfer.rejected, (state, {payload, error}) => {
            state.authorizeStatus = 'failed';
            state.authSummary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: complete bank transfer reducer
        builder.addCase(completeBankTransfer.pending, (state, _) => {
            state.finalizeStatus = 'pending';
        })
        builder.addCase(completeBankTransfer.fulfilled, (state, _) => {
            state.finalizeStatus = 'succeeded';
            state.initiateStatus = 'idle';
            state.authorizeStatus = 'idle';
            state.summary = null;
            state.authSummary = null;
        })
        builder.addCase(completeBankTransfer.rejected, (state, {payload, error}) => {
            state.finalizeStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: initiate business transfer reducer
        builder.addCase(initiateBusinessTransfer.pending, (state, _) => {
            state.initiateStatus = 'pending';
        })
        builder.addCase(initiateBusinessTransfer.fulfilled, (state, {payload}) => {
            state.initiateStatus = 'succeeded';
            state.summary = payload;
        })
        builder.addCase(initiateBusinessTransfer.rejected, (state, {payload, error}) => {
            state.initiateStatus = 'failed';
            state.summary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: authorize business transfer reducer
        builder.addCase(authorizeBusinessTransfer.pending, (state, _) => {
            state.authorizeStatus = 'pending';
        })
        builder.addCase(authorizeBusinessTransfer.fulfilled, (state, {payload}) => {
            state.authorizeStatus = 'succeeded';
            state.authSummary = payload;
        })
        builder.addCase(authorizeBusinessTransfer.rejected, (state, {payload, error}) => {
            state.authorizeStatus = 'failed';
            state.authSummary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: complete bank transfer reducer
        builder.addCase(completeBusinessTransfer.pending, (state, _) => {
            state.finalizeStatus = 'pending';
        })
        builder.addCase(completeBusinessTransfer.fulfilled, (state, _) => {
            state.finalizeStatus = 'succeeded';
            state.initiateStatus = 'idle';
            state.authorizeStatus = 'idle';
            state.summary = null;
            state.authSummary = null;
        })
        builder.addCase(completeBusinessTransfer.rejected, (state, {payload, error}) => {
            state.finalizeStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: initiate mobile transfer reducer
        builder.addCase(initiateMobileTransfer.pending, (state, _) => {
            state.initiateStatus = 'pending';
        })
        builder.addCase(initiateMobileTransfer.fulfilled, (state, {payload}) => {
            state.initiateStatus = 'succeeded';
            state.summary = payload;
        })
        builder.addCase(initiateMobileTransfer.rejected, (state, {payload, error}) => {
            state.initiateStatus = 'failed';
            state.summary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: authorize mobile transfer reducer
        builder.addCase(authorizeMobileTransfer.pending, (state, _) => {
            state.authorizeStatus = 'pending';
        })
        builder.addCase(authorizeMobileTransfer.fulfilled, (state, {payload}) => {
            state.authorizeStatus = 'succeeded';
            state.authSummary = payload;
        })
        builder.addCase(authorizeMobileTransfer.rejected, (state, {payload, error}) => {
            state.authorizeStatus = 'failed';
            state.authSummary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: complete mobile transfer reducer
        builder.addCase(completeMobileTransfer.pending, (state, _) => {
            state.finalizeStatus = 'pending';
        })
        builder.addCase(completeMobileTransfer.fulfilled, (state, _) => {
            state.finalizeStatus = 'succeeded';
            state.initiateStatus = 'idle';
            state.authorizeStatus = 'idle';
            state.summary = null;
            state.authSummary = null;
        })
        builder.addCase(completeMobileTransfer.rejected, (state, {payload, error}) => {
            state.finalizeStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: resend transfer otp reducer
        builder.addCase(resendTransferOTP.pending, (state, _) => {
            state.resending = 'pending';
        })
        builder.addCase(resendTransferOTP.fulfilled, (state, _) => {
            state.resending = 'succeeded';
        })
        builder.addCase(resendTransferOTP.rejected, (state, {payload, error}) => {
            state.resending = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: complete pending transfer reducer
        builder.addCase(completePendingTransfer.pending, (state, _) => {
            state.completed = 'pending';
        })
        builder.addCase(completePendingTransfer.fulfilled, (state, _) => {
            state.completed = 'succeeded';
        })
        builder.addCase(completePendingTransfer.rejected, (state, {payload, error}) => {
            state.completed = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: initiate counterparty transfer reducer
        builder.addCase(initiateCounterPartyTransfer.pending, (state, _) => {
            state.resendTransfer = 'pending';
        })
        builder.addCase(initiateCounterPartyTransfer.fulfilled, (state, {payload}) => {
            state.resendTransfer = 'succeeded';
            state.summary = payload;
        })
        builder.addCase(initiateCounterPartyTransfer.rejected, (state, {payload, error}) => {
            state.resendTransfer = 'failed';
            state.summary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: authorize counterparty transfer reducer
        builder.addCase(authorizeCounterPartyTransfer.pending, (state, _) => {
            state.authorizeStatus = 'pending';
        })
        builder.addCase(authorizeCounterPartyTransfer.fulfilled, (state, {payload}) => {
            state.authorizeStatus = 'succeeded';
            state.authSummary = payload;
        })
        builder.addCase(authorizeCounterPartyTransfer.rejected, (state, {payload, error}) => {
            state.authorizeStatus = 'failed';
            state.authSummary = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: resend counterparty transfer reducer
        builder.addCase(resendCounterPartyTransfer.pending, (state, _) => {
            state.finalizeStatus = 'pending';
        })
        builder.addCase(resendCounterPartyTransfer.fulfilled, (state, _) => {
            state.finalizeStatus = 'succeeded';
            state.authSummary = null;
            state.summary = null;
        })
        builder.addCase(resendCounterPartyTransfer.rejected, (state, {payload, error}) => {
            state.finalizeStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export const {resetAuthorizeStatus} = transferSlice.actions;

export default transferSlice.reducer;
