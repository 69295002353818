import {configureStore} from '@reduxjs/toolkit';
import authReducer from './auth/auth-slice';
import localeReducer from './locale/locale-slice';
import referralReducer from './referrals/referrals-slice';
import settingsReducer from './settings/settings-slice';
import banksReducer from './banks/banks-slice';
import businessReducer from './business/business-slice';
import currencyReducer from './currency/currency-slice';
import complianceReducer from './compliance/compliance-slice';
import invoiceReducer from './invoices/invoices-slice';
import industryReducer from './industry/industry-slice';
import paymentLinksReducer from './payment-links/payment-links-slice';
import connectReducer from './connect/connect-slice';
import teamReducer from './teams/teams-slice';
import billReducer from './bills/bills-slice';
import balanceReducer from './balance/balance-slice';
import transactionReducer from './transactions/transactions-slice';
import dashboardReducer from './dashboard/dashboard-slice';
import transferReducer from './transfers/transfers-slice';
import providerReducer from './providers/providers-slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        banks: banksReducer,
        balance: balanceReducer,
        business: businessReducer,
        bills: billReducer,
        connect: connectReducer,
        compliance: complianceReducer,
        currency: currencyReducer,
        dashboard: dashboardReducer,
        invoices: invoiceReducer,
        industry: industryReducer,
        locale: localeReducer,
        paymentLinks: paymentLinksReducer,
        providers: providerReducer,
        referral: referralReducer,
        settings: settingsReducer,
        teams: teamReducer,
        transactions: transactionReducer,
        transfers: transferReducer,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;