import React from 'react';

import './styles.less';
import IMAGES from '../../../constants/images';

const Loading:React.FC = ():JSX.Element => {
  return (
    <div className="loading__fallback">
      <img src={IMAGES.logo_icon_muted} alt="pesawise" />
      {/* <br/>
      <p>Loading...</p> */}
    </div>
  )
}

export default Loading;