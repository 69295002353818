import React from 'react';
import {Row, Col} from 'antd';

import './styles.less';
import IMAGES from '../../../../constants/images';

interface IProps{
    as?: string;
}

const AuthVector:React.FC<IProps> = ({as}) => {
    return (
        <div className="auth__vector">
            <div className="auth__vector__circle"/>

            <div className="auth__vector__square">
                <img className="auth__vector__square__logo" src={IMAGES.logo_icon_muted} alt="pesawise"/>

                <figure className="auth__vector__square__icon">
                    <img src={IMAGES.user__icon} alt="user"/>
                </figure>

                <div className="auth__vector__square__line"/>
                <div className="auth__vector__square__line --small"/>

                {as === 'password' ? (
                    <Row justify="space-around" className="auth__vector__square__row">
                        <Col>
                            <Row gutter={[12, 0]}>
                                <Col>
                                    <div className="auth__vector__square__box">
                                        <div className="auth__vector__square__box__dot"/>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="auth__vector__square__box">
                                        <div className="auth__vector__square__box__dot"/>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="auth__vector__square__box">
                                        <div className="auth__vector__square__box__dot"/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row gutter={[12, 0]}>
                                <Col>
                                    <div className="auth__vector__square__box">
                                        <div className="auth__vector__square__box__dot"/>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="auth__vector__square__box">
                                        <div className="auth__vector__square__box__dot"/>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="auth__vector__square__box">
                                        <div className="auth__vector__square__box__dot"/>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <p className="auth__vector__square__text">
                        example@email.com
                    </p>
                )}

            </div>
        </div>
    )
}

export default AuthVector;
