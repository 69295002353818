import React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {Row, Col, Card, Form, Input, Button, Checkbox} from 'antd';

import './styles.less';
import Logo from '../../components/ui/logo';
import AuthVector from '../../components/ui/vectors/auth';

import {clearCredentials, loginUser, saveCredentials} from '../../redux/auth/auth-slice';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';

interface IProps{
    history: RouteComponentProps["history"];
    match: RouteComponentProps["match"];
}

interface IUserInfo{
    username: string;
    password: string;
    captchaToken: string;
}

const SignIn:React.FC<IProps> = ({history}):JSX.Element => {
    const {status, user} = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();

    const [form] = Form.useForm();
    const creds:string | null | undefined = localStorage.getItem('@pesawise-remember-me');

    React.useEffect(() => {
        if(user) history.push('/get-started')
    },[user, history]);

    React.useEffect(() => {
        if(creds){
            form.setFieldsValue({
                'username': JSON.parse(creds),
                'remember': true
            })
        }
    },[form, creds]);

    const onFinish = async (values: any):Promise<any> => {
        const userInfo:IUserInfo = Object.assign({captchaToken: ''}, values);
        const {error, payload}:any = await dispatch(loginUser(userInfo));
        
        if(error && payload?.detail === 'NOT_ACTIVATED'){
            history.push(`/email/verification?verify=${userInfo.username}`);
        }
    }

    const onFinishFailed = (errorInfo: any): void => {
        console.log('Failed:', errorInfo);
    };

    const goToRegister = ():void => history.push('/register');

    const onChecked = (e:any):void => {
        const {checked} = e.target;
        const email = form.getFieldValue('username');
        if(email && checked){
            dispatch(saveCredentials(email));
        }
        else if(!email && checked) return;
        else {
            dispatch(clearCredentials());
        }
    }

    return (
        <section className="login">
            <Row justify="center">
                <Col xl={16} lg={24} md={24} sm={24} xs={24}>
                    <Card bordered={false} className="login__form">
                        <Logo/>
                        <Row className="login__form__row" justify="center" align='middle'>
                            <Col xl={10} lg={9 } md={12} sm={16} xs={22}>
                                <h1 className="login__form__heading">
                                    Welcome to pesawise
                                </h1>

                                <p className="login__form__text --space-bottom">
                                    A borderless account, with powerful, personalised tools all 
                                    in one place, giving you ultimate control over your 
                                    business finances.
                                </p>

                                <Form
                                    form={form}
                                    name="login"
                                    initialValues={{remember: false}}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <Form.Item
                                        name="username"
                                        label="Email address"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Email address is required!' }]}
                                    >
                                        <Input placeholder="example@email.com" size="large" autoComplete="off" />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        hasFeedback
                                        rules={[{ required: true, message: 'Password is required!' }]}
                                    >
                                        <Input.Password placeholder="********" size="large" autoComplete="off" />
                                    </Form.Item>

                                    <p className="login__form__link">
                                        This page is protected by reCAPTCHA and subject to the Google{' '}
                                        <a href="https://policies.google.com/privacy?hl=en-GB" target="_blank" rel="noreferrer">
                                            Privacy Policy
                                        </a> and {' '}
                                        <a href="https://policies.google.com/terms?hl=en-GB" target="_blank" rel="noreferrer">
                                            Terms of service
                                        </a>
                                    </p>

                                    <Form.Item name="remember" valuePropName="checked">
                                        <Checkbox onChange={onChecked}>Remember me</Checkbox>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button 
                                            block
                                            size="large"
                                            type="primary" 
                                            htmlType="submit"
                                            loading={status === 'pending'}
                                            className="login__btn"
                                        >
                                            Sign in
                                        </Button>
                                    </Form.Item>

                                    <Link to="/password/forgot">
                                        Trouble logging in?
                                    </Link>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col xl={8} lg={0} md={0} sm={0} xs={0}>
                    <Card bordered={false} className="login__banner">
                        <Row gutter={[20, 0]} justify="end" align="middle">
                            <Col>
                                <p className="login__banner__text">
                                    Don't have an account?
                                </p>
                            </Col>
                            <Col>
                                <Button onClick={goToRegister} size="large">
                                    Sign up
                                </Button>    
                            </Col>
                        </Row>

                        <div className="login__banner__container">
                            <AuthVector/>
                        </div>
                    </Card>
                </Col>
            </Row>
        </section>
    )
}

export default SignIn;
