import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {IReferral, IReferralPayload, TReferralState, IRequestError} from './referrals-slice-types';

export const getReferrals = createAsyncThunk<
    IReferral[],
    number,
    {rejectValue: IRequestError}
>(
    "REFERRALS/LIST", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-referrals/referrals?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const sendReferral = createAsyncThunk<
    IReferral[],
    IReferralPayload,
    {rejectValue: IRequestError}
>(
    "REFERRALS/CREATE", async (ref, thunkApi) => {
        const refData = Object.assign({}, ref);
        delete refData.businessId;

        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-referrals/create-referral?businessId=${ref.businessId}`, refData, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const referralSlice = createSlice({
    name: 'referrals',
    initialState: {
        status: 'idle',
        referred: 'idle',
        referrals: [],
        error: '',
    } as TReferralState,
    reducers: {},
    extraReducers: (builder) => {
        //@: send referral reducers
        builder.addCase(getReferrals.pending, (state, _) => {
            state.status = 'pending';
            state.error = '';
        })
        builder.addCase(getReferrals.fulfilled, (state, {payload}) => {
            state.referrals = payload;
            state.status = 'succeeded';
        })
        builder.addCase(getReferrals.rejected, (state, {payload, error}) => {
            state.referrals = [];
            state.status = 'failed';
            state.error = payload ? payload.detail : error.message;
        })

        //@: get referral reducers
        builder.addCase(sendReferral.pending, (state, _) => {
            state.referred = 'pending';
        })
        builder.addCase(sendReferral.fulfilled, (state, _) => {
            state.referred = 'succeeded';
        })
        builder.addCase(sendReferral.rejected, (state, {payload, error}) => {
            state.referred = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default referralSlice.reducer;
