import React from 'react'; 
import {useHistory} from 'react-router-dom';

import './styles.less';
import IMAGES from '../../../constants/images';

const Logo:React.FC = () => {
    const history = useHistory();

    return (
        <div onClick={() => history.push('/')} className="logo__container">
            <img className="logo__lg" src={IMAGES.logo} alt="pesawise"/>
            <img className="logo__sm" src={IMAGES.logo_icon} alt="pesawise"/>
        </div>
    )
}

export default Logo;
