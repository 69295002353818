import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {ITeam, ITeamRoles, ITeamPayload, ITeamUpdatePayload, ITeamState, IRequestError} from './teams-slice-types';

export const getTeams = createAsyncThunk<
    ITeam[],
    number,
    {rejectValue: IRequestError}
>(
    "TEAMS", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/teams/get-team?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getTeamsRoles = createAsyncThunk<
    ITeamRoles[],
    undefined,
    {rejectValue: IRequestError}
>(
    "TEAMS_ROLES", async (_, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/teams/get-valid-roles`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const createTeam = createAsyncThunk<
    ITeam,
    ITeamPayload,
    {rejectValue: IRequestError}
>(
    "TEAMS/CREATE", async (info, thunkApi) => {
        const teamInfo = Object.assign({}, info);
        delete teamInfo.businessId;

        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/teams/create-team-member?businessId=${info.businessId}`, teamInfo, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const deleteTeam = createAsyncThunk<
    {id: number;},
    {businessId: number; userId: number;},
    {rejectValue: IRequestError}
>(
    "TEAMS/DELETE", async (params, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.delete(`/teams/remove-team-member?businessId=${params.businessId}&userId=${params.userId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const updateTeam = createAsyncThunk<
    ITeam,
    ITeamUpdatePayload,
    {rejectValue: IRequestError}
>(
    "TEAMS/UPDATE", async (params, thunkApi) => {
        try {
            const payload = {...params};
            delete payload.businessId;
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.put(`/teams/change-role?businessId=${params.businessId}`, payload, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const teamSlice = createSlice({
    name: 'teams',
    initialState: {
        status: 'idle',
        roleStatus: 'idle',
        creating: 'idle',
        updating: 'idle',
        deleting: 'idle',
        teams: [],
        teamRoles: [],
        error: null,
    } as ITeamState,
    reducers: {},
    extraReducers: (builder) => {
        //@: get all teams reducers
        builder.addCase(getTeams.pending, (state, _) => {
            state.status = 'pending';
            state.creating = 'idle';
            state.error = null;
        })
        builder.addCase(getTeams.fulfilled, (state, {payload}) => {
            state.teams = payload;
            state.status = 'succeeded';
        })
        builder.addCase(getTeams.rejected, (state, {payload, error}) => {
            state.teams = [];
            state.status = 'failed';
            state.error = payload ? payload : error.message
        })

        //@: Get all team roles reducers
        builder.addCase(getTeamsRoles.pending, (state, _) => {
            state.roleStatus = 'pending';
        })
        builder.addCase(getTeamsRoles.fulfilled, (state, {payload}) => {
            state.teamRoles = payload;
            state.roleStatus = 'succeeded';
        })
        builder.addCase(getTeamsRoles.rejected, (state, {payload, error}) => {
            state.teams = [];
            state.roleStatus = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: Create a new team reducers
        builder.addCase(createTeam.pending, (state, _) => {
            state.creating = 'pending';
        })
        builder.addCase(createTeam.fulfilled, (state, _) => {
            state.creating = 'succeeded';
        })
        builder.addCase(createTeam.rejected, (state, {payload, error}) => {
            state.creating = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: Update a team member reducers
        builder.addCase(updateTeam.pending, (state, _) => {
            state.updating = 'pending';
        })
        builder.addCase(updateTeam.fulfilled, (state, {payload}) => {
            state.updating = 'succeeded';
            state.teams = state.teams.map(team => team.id === payload.id ? payload : team);
        })
        builder.addCase(updateTeam.rejected, (state, {payload, error}) => {
            state.updating = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: Delete a team member reducers
        builder.addCase(deleteTeam.pending, (state, _) => {
            state.deleting = 'pending';
        })
        builder.addCase(deleteTeam.fulfilled, (state, {payload}) => {
            state.deleting = 'succeeded';
            state.teams = state.teams.filter(team => team.id !== payload.id);
        })
        builder.addCase(deleteTeam.rejected, (state, {payload, error}) => {
            state.deleting = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default teamSlice.reducer;
