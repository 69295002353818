import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {IBill, TBillRefPayload, IBillReference, TBillPayload, IBillPaySlip, IBillAuthorise, IBillState, IRequestError} from './bills-slice-types';

export const getBills = createAsyncThunk<
    IBill[],
    number,
    {rejectValue: IRequestError}
>(
    "BILLS/LIST", async (localeId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/business-billers/billers?localeId=${localeId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const validateBillRef = createAsyncThunk<
    IBillReference,
    TBillRefPayload,
    {rejectValue: IRequestError}
>(
    "BILLS/VALIDATE-REF", async (billReference, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-billers/initiate-bill-payment-two-way`, billReference, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getBillsFee = createAsyncThunk<
    any,
    TBillPayload,
    {rejectValue: IRequestError}
>(
    "BILLS/FEE", async (bill, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-billers/get-fee`, bill, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const payBills = createAsyncThunk<
    IBillPaySlip,
    TBillPayload,
    {rejectValue: IRequestError}
>(
    "BILLS/PAY", async (bill, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-billers/create-bill-payment`, bill, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const authoriseBillPayment = createAsyncThunk<
    IBillAuthorise,
    {paymentId: number; token: string},
    {rejectValue: IRequestError}
>(
    "BILLS/AUTHORISE-PAYMENT", async (payload, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/business-billers/complete-bill-payment?paymentId=${payload.paymentId}&token=${payload.token}`, null, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const billSlice = createSlice({
    name: 'bills',
    initialState: {
        status: 'idle',
        validating: 'idle',
        paying: 'idle',
        authorise: 'idle',
        billing: 'idle',
        paySlip: null,
        fee: null,
        bills: [],
        categories: [],
        reference: null,
        error: '',
    } as IBillState,
    reducers: {},
    extraReducers: (builder) => {
        //@: get all bills reducer
        builder.addCase(getBills.pending, (state, _) => {
            state.status = 'pending';
            state.validating = 'idle';
            state.paying = 'idle';
            state.authorise = 'idle';
            state.reference = null;
            state.paySlip = null;
            state.error = '';
        })
        builder.addCase(getBills.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.bills = payload;
            state.categories = ['ALL BILLERS', ...new Set(payload.map(item => item.billerType))];
        })
        builder.addCase(getBills.rejected, (state, {payload, error}) => {
            state.bills = [];
            state.status = 'failed';
            state.error = payload ? payload : error.message;
        })

        //@: validate bill reference reducer
        builder.addCase(validateBillRef.pending, (state, _) => {
            state.validating = 'pending';
        })
        builder.addCase(validateBillRef.fulfilled, (state, {payload}) => {
            state.validating = 'succeeded';
            state.reference = payload;
            message.success('Reference validated.')
        })
        builder.addCase(validateBillRef.rejected, (state, {payload, error}) => {
            state.reference = null;
            state.validating = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: pay bills reducer
        builder.addCase(getBillsFee.pending, (state, _) => {
            state.billing = 'pending';
        })
        builder.addCase(getBillsFee.fulfilled, (state, {payload}) => {
            state.billing = 'succeeded';
            state.fee = payload;
        })
        builder.addCase(getBillsFee.rejected, (state, {payload, error}) => {
            state.billing = 'failed';
            state.fee = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: pay bills reducer
        builder.addCase(payBills.pending, (state, _) => {
            state.paying = 'pending';
        })
        builder.addCase(payBills.fulfilled, (state, {payload}) => {
            state.paying = 'succeeded';
            state.paySlip = payload;
        })
        builder.addCase(payBills.rejected, (state, {payload, error}) => {
            state.reference = null;
            state.paying = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: authorise bill payment reducer
        builder.addCase(authoriseBillPayment.pending, (state, _) => {
            state.authorise = 'pending';
        })
        builder.addCase(authoriseBillPayment.fulfilled, (state, _) => {
            state.authorise = 'succeeded';
        })
        builder.addCase(authoriseBillPayment.rejected, (state, {payload, error}) => {
            state.authorise = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default billSlice.reducer;
