import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosError} from 'axios';
import {message} from 'antd';

import {axiosRequest} from '../../helpers/request';
import {ISBusiness, ISBusinessPayload, ISPersonal, ISBanks, ISKeys, ISBanksPayload, ISBankDeletePayload, TSettingsState, IRequestError, IWebhooks} from './settings-slice-types';

export const getBusinessSettings = createAsyncThunk<
    ISBusiness,
    number,
    {rejectValue: IRequestError}
>(
    "SETTINGS/GET-BUSINESS", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/settings/get-business-settings?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const editBusinessSettings = createAsyncThunk<
    ISBusiness,
    ISBusinessPayload,
    {rejectValue: IRequestError}
>(
    "SETTINGS/EDIT-BUSINESS", async (info, thunkApi) => {
        const userInfo = Object.assign({}, info);
        delete userInfo.businessId;
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.put(`/settings/change-business-settings?businessId=${info.businessId}`, userInfo, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getPersonalSettings = createAsyncThunk<
    ISPersonal,
    undefined,
    {rejectValue: IRequestError}
>(
    "SETTINGS/GET-PERSONAL", async (_, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/settings/get-user-settings`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const editPersonalSettings = createAsyncThunk<
    ISPersonal,
    ISPersonal,
    {rejectValue: IRequestError}
>(
    "SETTINGS/EDIT-PERSONAL", async (userInfo, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.put(`/settings/change-user-settings`, userInfo, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getBankSettings = createAsyncThunk<
    ISBanks[],
    number,
    {rejectValue: IRequestError}
>(
    "SETTINGS/GET-BANKS", async (businessId, thunkApi) => {
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.get(`/settings/get-bank-accounts?businessId=${businessId}`, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const addBankSettings = createAsyncThunk<
    ISBanks,
    ISBanksPayload,
    {rejectValue: IRequestError}
>(
    "SETTINGS/CREATE-BANK", async (bank, thunkApi) => {
        const bankInfo = Object.assign({}, bank);
        delete bankInfo.businessId;
        try {
            const config = {headers: {"Content-Type": "application/json"}};
            const res = await axiosRequest.post(`/settings/add-bank-account?businessId=${bank.businessId}`, bankInfo, config);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const deleteBankSettings = createAsyncThunk<
    ISBanks,
    ISBankDeletePayload,
    {rejectValue: IRequestError}
>(
    "SETTINGS/DELETE-BANK", async (bank, thunkApi) => {
        const bankInfo = Object.assign({}, bank);
        delete bankInfo.businessId;
        try {
            const res = await axiosRequest.delete(`/settings/remove-bank-account?businessId=${bank.businessId}&bankAccountId=${bankInfo.bankId}`);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);


export const getApiKeys = createAsyncThunk<
    ISKeys,
    number,
    {rejectValue: IRequestError}
>(
    "SETTINGS/API", async (businessId, thunkApi) => {
        try {
            const res = await axiosRequest.get(`/settings/get-api-keys?businessId=${businessId}`);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const generateApiKeys = createAsyncThunk<
    ISKeys,
    number,
    {rejectValue: IRequestError}
>(
    "SETTINGS/API-REFRESH", async (businessId, thunkApi) => {
        try {
            const res = await axiosRequest.put(`/settings/regenerate-api-keys?businessId=${businessId}`, null);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const getWebhooks = createAsyncThunk<
    IWebhooks,
    number,
    {rejectValue: IRequestError}
>(
    "SETTINGS/GET-WEBHOOKS", async (businessId, thunkApi) => {
        try {
            const res = await axiosRequest.get(`/settings/get-webhook-settings?businessId=${businessId}`);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const editWebhook = createAsyncThunk<
    any,
    IWebhooks,
    {rejectValue: IRequestError}
>(
    "SETTINGS/EDIT-WEBHOOKS", async (payload, thunkApi) => {
        try {
            const webhook = {...payload};
            delete webhook.businessId;
            const res = await axiosRequest.put(`/settings/change-webhook-settings?businessId=${payload.businessId}`, webhook);
            return res.data;
        } 
        catch (err:any) {
            let error: AxiosError<IRequestError> = err;
            if(!error.response){
                throw err;
            }
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

const referralSlice = createSlice({
    name: 'settings',
    initialState: {
        status: 'idle',
        updated: 'idle',
        created: 'idle',
        deleted: 'idle',
        editing: 'idle',
        generating: 'idle',
        business: null,
        personal: null,
        banks: [],
        webhooks: null,
        keys: null,
        businessError: null,
        personalError: null,
        banksError: null,
        apiError: null,
        webhooksError: null
    } as TSettingsState,
    reducers: {},
    extraReducers: (builder) => {
        //@: get business settings reducer
        builder.addCase(getBusinessSettings.pending, (state, _) => {
            state.status = 'pending';
            state.businessError = null;
        })
        builder.addCase(getBusinessSettings.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.business = payload;
        })
        builder.addCase(getBusinessSettings.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            state.business = null;
            state.businessError = payload ? payload : error.message;
        })

        //@: edit business settings reducers
        builder.addCase(editBusinessSettings.pending, (state, _) => {
            state.updated = 'pending';
        })
        builder.addCase(editBusinessSettings.fulfilled, (state, {payload}) => {
            state.updated = 'succeeded';
            state.business = payload;
            message.success('Business settings updated.');
        })
        builder.addCase(editBusinessSettings.rejected, (state, {payload, error}) => {
            state.updated = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get personal settings reducers
        builder.addCase(getPersonalSettings.pending, (state, _) => {
            state.status = 'pending';
            state.personalError = null;
        })
        builder.addCase(getPersonalSettings.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.personal = payload;
        })
        builder.addCase(getPersonalSettings.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            state.personal = null;
            state.personalError = payload ? payload : error.message;
        })

        //@: edit business settings reducers
        builder.addCase(editPersonalSettings.pending, (state, _) => {
            state.updated = 'pending';
        })
        builder.addCase(editPersonalSettings.fulfilled, (state, {payload}) => {
            state.updated = 'succeeded';
            state.personal = payload;
            message.success('Personal settings updated.');
        })
        builder.addCase(editPersonalSettings.rejected, (state, {payload, error}) => {
            state.updated = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get all bank settings reducers
        builder.addCase(getBankSettings.pending, (state, _) => {
            state.status = 'pending';
            state.created = 'idle';
            state.banksError = null;
        })
        builder.addCase(getBankSettings.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.banks = payload;
        })
        builder.addCase(getBankSettings.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            state.banksError = payload ? payload : error.message;
        })

        //@: add bank settings reducers
        builder.addCase(addBankSettings.pending, (state, _) => {
            state.created = 'pending';
        })
        builder.addCase(addBankSettings.fulfilled, (state, {payload}) => {
            state.created = 'succeeded';
            state.banks = [...state.banks, payload];
        })
        builder.addCase(addBankSettings.rejected, (state, {payload, error}) => {
            state.created = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: delete bank settings reducers
        builder.addCase(deleteBankSettings.pending, (state, _) => {
            state.deleted = 'pending';
        })
        builder.addCase(deleteBankSettings.fulfilled, (state, {payload}) => {
            state.deleted = 'succeeded';
            state.banks = state.banks.filter(bank => bank.id !== payload.id);
        })
        builder.addCase(deleteBankSettings.rejected, (state, {payload, error}) => {
            state.deleted = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get api keys reducer
        builder.addCase(getApiKeys.pending, (state, _) => {
            state.status = 'pending';
            state.apiError = null;
        })
        builder.addCase(getApiKeys.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.keys = payload;
        })
        builder.addCase(getApiKeys.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            state.keys = null;
            state.apiError = payload ? payload : error.message;
        })

        //@: generate new api keys reducer
        builder.addCase(generateApiKeys.pending, (state, _) => {
            state.generating = 'pending';
        })
        builder.addCase(generateApiKeys.fulfilled, (state, {payload}) => {
            state.generating = 'succeeded';
            state.keys = payload;
        })
        builder.addCase(generateApiKeys.rejected, (state, {payload, error}) => {
            state.generating = 'failed';
            state.keys = null;
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })

        //@: get webhooks reducer
        builder.addCase(getWebhooks.pending, (state, _) => {
            state.status = 'pending';
            state.webhooksError = null;
        })
        builder.addCase(getWebhooks.fulfilled, (state, {payload}) => {
            state.status = 'succeeded';
            state.webhooks = payload;
        })
        builder.addCase(getWebhooks.rejected, (state, {payload, error}) => {
            state.status = 'failed';
            state.webhooks = null;
            state.webhooksError = payload ? payload : error.message
        })

        //@: edit webhooks reducer
        builder.addCase(editWebhook.pending, (state, _) => {
            state.editing = 'pending';
        })
        builder.addCase(editWebhook.fulfilled, (state, _action) => {
            state.editing = 'succeeded';
        })
        builder.addCase(editWebhook.rejected, (state, {payload, error}) => {
            state.editing = 'failed';
            if(payload?.detail){
                message.error(payload.detail);
            }else{
                message.error(error.message)
            }
        })
    },
});

export default referralSlice.reducer;
