import React from 'react';
import { Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './styles.less';
import IMAGES from '../../constants/images';

interface IProps {
    history: RouteComponentProps["history"];
}

const NotFound: React.FC<IProps> = ({ history }): JSX.Element => {
    const goToHome = (): void => {
        history.goBack()
    }

    return (
        <section className="not__found">
            <img className="not__found__img" src={IMAGES.not_found} alt="not-found" />
            <h2 className="not__found__title">
                uh-oh! Page not found.
            </h2>
            <p className="not__found__text">
                The page you are looking for was likely abducted by
                aliens. Go back home and lock your windows.
            </p>
            <Button icon={<ArrowLeftOutlined />} onClick={goToHome} type="primary" size="large">
                Use an escape pod
            </Button>
        </section>
    )
}

export default NotFound;